<template>
    <div>

        <table class="table">
            <thead>
            <tr>
                <th>Registration</th>
                <th>Make</th>
                <th>Model</th>
                <th>Colour</th>
                <th>Fuel Type</th>
                <th>Events on Site</th>
                <th>Last Seen on Site</th>
                <th v-if="$can('claims-process')"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{{claim.vehicle.plate}}</td>
                <td>{{claim.override_mmc == 1 ? claim.vehicle_make : claim.make}}</td>
                <td>{{claim.override_mmc == 1 ? claim.vehicle_model : claim.model}}</td>
                <td>{{claim.override_mmc == 1 ? claim.vehicle_colour : claim.colour}}</td>
                <td>{{claim.override_mmc == 1 ? claim.vehicle_fueltype : claim.fuelType}}</td>
                <td>{{claim.eventCount}}</td>
                <td>{{claim.lastEvent ? claim.lastEvent.created_at : 'No Events'}}</td>
                <td v-if="$can('claims-process')"><button class="float-right btn btn-sm btn-warning mg-l-10" @click="openEditModal">Override MMC</button></td>
            </tr>
            </tbody>
        </table>

        <div class="row d-flex justify-content-center flex-wrap">

            <div class="receipt">
                <div class="receipt-body">
                    <div class="receipt-header">
                        <span class="tx-uppercase">DRIVE OFF</span>
                    </div>
                    <div>
                        <p><span class="tx-bold">Amount: </span> <span class="ft-right">{{driveoff.amount}}</span></p>
                    </div>
                </div>
                <div class="receipt-footer">
                    £{{driveoff.value}}
                </div>
            </div>

            <div class="receipt">
                <div class="receipt-body">
                    <div class="receipt-header">
                        <span class="tx-uppercase">NO MEANS OF PAYMENT</span>
                    </div>
                    <div>
                        <p><span class="tx-bold">Amount: </span> <span class="ft-right">{{nmop.amount}}</span></p>
                    </div>
                </div>
                <div class="receipt-footer">
                    £{{nmop.value}}
                </div>
            </div>

            <div class="receipt">
                <div class="receipt-body">
                    <div class="receipt-header">
                        <span class="tx-uppercase">PARKING</span>
                    </div>
                    <div>
                        <p><span class="tx-bold">Amount: </span> <span class="ft-right">{{parking.amount}}</span></p>
                    </div>
                </div>
                <div class="receipt-footer">
                    £{{parking.value}}
                </div>
            </div>

        </div>

        <div class="row d-flex justify-content-center">
            <div class="subtotal tx-white">
                <p>£{{parseFloat(parseFloat(driveoff.value) + parseFloat(nmop.value) + parseFloat(parking.value)).toFixed(2)}} Inc. VAT</p>
            </div>
        </div>

        <div class="row mg-t-20">
            <div class="col-12">
                <p class="tx-white tx-medium tx-16">Vehicle Events - {{claim.site.name}}</p>
                <hr style="color: white; border: 1px solid white; margin-top: 0.1rem; margin-left: 0px;" :style="{width: `Vehicle Events - ${claim.site.name}`.length + 'ch'}"/>
            </div>
        </div>

        <div class="row">
            <div class="col-2">
                <div class="card shadow-base bg-info widget-11" style="cursor: pointer;" @click="toggleFilters">
                    <div class="card-header" style="border: none;">
                        <div class="card-title">Filters <span v-if="selectedFilters > 0">({{selectedFilters}} selected)</span></div>
                        <a href="javascript:void(0);"><i v-if="!filters.open" class="fa fa-angle-down"></i><i v-else class="fa fa-angle-up"></i></a>
                    </div><!-- card-header -->
                </div>
            </div>
            <div class="col-10">
                <pagination :data="pagination" @search="pagiSearch"></pagination>
            </div>
        </div>
        <div class="row">
            <div v-show="filters.open" class="col-2">
                <!-- SEARCH BUTTON -->
                <button v-show="!optionsMatch" @click="applyFilters" class="btn btn-primary mg-t-20 pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p">APPLY FILTERS</button>

                <!-- CLEAR FILTERS BUTTON -->
                <button v-show="selectedFilters > 0" @click="clearFilters" class="btn btn-danger mg-t-20 pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p">CLEAR FILTERS</button>

                <!-- TEXT FILTERS -->
                <div class="card shadow-base mg-t-20 widget-11" style="border: 1px dashed white;">
                    <div class="card-header">
                        <div class="card-title">Search</div>
                    </div><!-- card-header -->
                    <div class="card-body">
                        <div class="form-group">
                            <select v-model="filters.options.direction" class="form-control select2" v-select2>
                                <option :value="''" :selected="filters.options.direction === ''">Select a Direction</option>
                                <option v-for="d in directions" v-bind:value="d">{{d}}</option>
                            </select>
                        </div>
                    </div><!-- card-block -->
                </div>

                <!-- TYPE FILTERS -->
                <div class="card shadow-base mg-t-20 widget-11" style="border: 1px dashed white;">
                    <div class="card-header">
                        <div class="card-title">Time</div>
                    </div><!-- card-header -->
                    <div class="card-body">
                        <div class="form-group mg-b-0">
                            <label>Start Date</label>
                            <input type="date" name="start_date" v-model="filters.options.start_date" class="form-control form-control-dark tx-14" placeholder="DD/MM/YYYY">
                        </div><!-- form-group -->
                        <div class="form-group mg-b-0">
                            <label>End Date</label>
                            <input type="date" name="end_date" v-model="filters.options.end_date" class="form-control form-control-dark tx-14" placeholder="DD/MM/YYYY">
                        </div><!-- form-group -->
                        <div class="form-group mg-b-0">
                            <label>Start Time</label>
                            <input type="text" name="start_time" v-model="filters.options.start_time" class="form-control form-control-dark tx-14" placeholder="hh:mm:ss">
                        </div><!-- form-group -->
                        <div class="form-group mg-b-0">
                            <label>End Time</label>
                            <input type="text" name="end_time" v-model="filters.options.end_time" class="form-control form-control-dark tx-14" placeholder="hh:mm:ss">
                        </div><!-- form-group -->
                    </div><!-- card-block -->
                </div>

            </div>
            <div :class="{'col-10': filters.open, 'col-12': !filters.open}" class="mg-t-20">
                <loader :show="loading"></loader>
                <table v-show="!loading" width="100%" class="table table-striped table-hover tx-white">
                    <thead class="thead-colored thead-primary">
                    <tr>
                        <th>#</th>
                        <th>PHOTO</th>
                        <th>STATUS</th>
                        <th>PLATE</th>
                        <th>I/O</th>
                        <th>DATETIME</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="e in events" :key="`${claim.id}_${e.id}`" v-if="!loading" style="cursor: pointer">
                        <td>{{e.id}}</td>
                        <td>
                            <secure-image v-if="e.image !== null && !e.grayscaled" @click="showImage(e)" style="width: 75px; height: auto;" :filename="e.image" :site_id="claim.site_id" :storage_account="e.storage_account"></secure-image>
                            <img v-else-if="e.image !== null && e.grayscaled" :src="e.grayscaled" style="width: 75px; height: auto;" @click="showImage(e)" />
                            <span v-else>Image Deleted</span>
                        </td>
                        <td v-if="e.status === 'blacklisted'">
                            Blacklisted <span v-if="e.national_blacklist">(On National Blacklist)</span>
                        </td>
                        <td v-if="e.status !== 'blacklisted'">
                            Active <span v-if="e.national_blacklist">(On National Blacklist)</span>
                        </td>
                        <td>{{e.vehicle.plate}}</td>
                        <td>{{e.io}}</td>
                        <td>{{e.created_at}}</td>
                        <td>
                            <submit-button  :clicked="grayscaling" @click="grayscale(e)" text="Grayscale" background="btn btn-sm btn-warning" v-if="$can('claims-process')"></submit-button>
                        </td>
                        <td>
                            <submit-button  :clicked="updatingEvidence" @click="addEventToEvidence(e)" text="Add to Evidence" background="btn btn-sm btn-primary" v-if="!e.evidence && $can('claims-process') && $can('claims-manage-team')"></submit-button>
                            <submit-button :clicked="updatingEvidence" @click="removeEventFromEvidence(e)" text="Remove from Evidence" background="btn btn-sm btn-danger" v-if="e.evidence && $can('claims-process') && $can('claims-manage-team')"></submit-button>
                            <span class="badge badge-primary" v-if="e.evidence && (!$can('claims-process') || !$can('claims-manage-team'))">Evidence</span>
                        </td>
                    </tr>
                    <tr v-if="events.length < 1 && !loading">
                        <td colspan="6" class="text-center">No Events</td>
                    </tr>
                    </tbody>
                </table>
                <pagination :data="pagination" @search="pagiSearch"></pagination>

            </div>
        </div>

        <div id="editModal" class="modal fade" style="display: none; padding-right: 17px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content bg-dark tx-white tx-size-sm">
                    <div class="modal-header bg-dark pd-x-20">
                        <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">Edit Vehicle Make, Model and Colour</h6>
                        <button type="button" class="close tx-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="tx-white">×</span>
                        </button>
                    </div>
                    <div class="modal-body bg-dark tx-white pd-20">

                        <div class="form-layout form-layout-2">
                            <div class="row no-gutters">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-control-label">Make: <span class="tx-danger">*</span></label>
                                        <input class="form-control disabled" type="text" v-model="make" placeholder="Vehicle make">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-control-label">Model: <span class="tx-danger">*</span></label>
                                        <input class="form-control disabled" type="text" v-model="model" placeholder="Vehicle model">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-control-label">Colour: <span class="tx-danger">*</span></label>
                                        <input class="form-control disabled" type="text" v-model="colour" placeholder="Vehicle colour">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-control-label">Fuel Type: <span class="tx-danger">*</span></label>
                                        <input class="form-control disabled" type="text" v-model="fuelType" placeholder="Vehicle fuel type">
                                    </div>
                                </div>
                            </div><!-- row -->
                            <div class="form-layout-footer bd pd-20 bd-t-0">
                                <submit-button :background="'btn btn-info'" :text="'Save Changes'" :clicked="creating" @click="edit" />
                                <button class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            </div><!-- form-group -->
                        </div>

                    </div><!-- modal-body -->
                    <div class="modal-footer bg-dark tx-white">
                        <button id="closeEditModal" type="button" class="btn btn-secondary tx-size-xs" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div><!-- modal-dialog -->
        </div>

        <div id="eventImageModal" v-if="popups.event.showing" class="modal fade effect-just-me show" style="display: block; padding-right: 17px;">
            <div class="modal-dialog modal-dialog-centered" style="max-width: 80% !important;" role="document">
                <div class="modal-content bd-0 tx-14">
                    <div class="modal-body pd-25">
                        <div class="row">
                            <div class="col-12">
                                <div class="img-magnifier-container">
                                    <secure-image v-show="!popups.event.imageLoading" :id="'event_image_popup'" :endpoint="'/api/event/image/'" :filename="popups.event.event.image" :site_id="claim.site_id" :storage_account="popups.event.event.storage_account" @load="imageLoaded" style="width: 100%; height: auto;"></secure-image>
                                </div>
                                <div v-show="popups.event.imageLoading">
                                    <div class="d-flex ht-300 pos-relative align-items-center">
                                        <div class="sk-folding-cube">
                                            <div class="sk-cube1 sk-cube"></div>
                                            <div class="sk-cube2 sk-cube"></div>
                                            <div class="sk-cube4 sk-cube"></div>
                                            <div class="sk-cube3 sk-cube"></div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <button class="btn btn-danger tx-11 tx-uppercase" @click="closeImage" style="position: absolute; top: 12px; right: 25px; z-index: 10000; font-size: 15px; font-weight: bold;" ><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- modal-dialog -->
        </div>

    </div>
</template>

<script>
import SubmitButton from "../../../components/SubmitButton";
import {DateTime} from "luxon";
import Pagination from "../../../components/Pagination";
import Loader from "../../../components/Loader";
import SecureImage from "../../../components/SecureImage";
import axios from 'axios';
import authHeader from "../../../services/auth-header";

export default {
    name: "Vehicle",
    props: {
        claim: Object,
        client_id: Number | String
    },
    components: {
        SecureImage,
        SubmitButton,
        Pagination,
        Loader
    },
    data(){
        return {
            driveoff: {
                amount: 0,
                value: 0
            },
            nmop: {
                amount: 0,
                value: 0
            },
            parking: {
                amount: 0,
                value: 0
            },
            make: null,
            model: null,
            colour: null,
            fuelType: null,
            creating: false,

            events: [],
            pagination: {
                currPage: 1,
                totalResults: 0,
                from: 0,
                to: 0,
                perPage: 0,
                lastPage: 1,
            },
            filters: {
                open: true,
                options: {
                    registration: "",
                    site: "",
                    camera: "",
                    direction: "",
                    status: "",
                    start_date: "",
                    end_date: "",
                    start_time: "",
                    end_time: ""
                },
                selectedOptions: {
                    registration: "",
                    site: "",
                    camera: "",
                    direction: "",
                    status: "",
                    start_date: "",
                    end_date: "",
                    start_time: "",
                    end_time: ""
                },
            },
            directions: [
                "In",
                "Out"
            ],
            popups: {
                event: {
                    showing: false,
                    imageUrl: "",
                    imageLoading: false,
                },
            },
            loading: false,
            updatingEvidence: false,
            grayscaling: false,
            objectUrls: []
        }
    },
    beforeRouteLeave(to, from, next){

        this.objectUrls.forEach((url) => {
            URL.revokeObjectURL(url);
        });

        document.removeEventListener('keydown', (e) => {
            if(e.code === 'Escape'){
                this.closeImage();
                $('#closeEditModal').click();
            }
        });
        next();
    },
    mounted(){
        document.addEventListener('keydown', (e) => {
            if(e.code === 'Escape'){
                this.closeImage();
                $('#closeEditModal').click();
            }
        });
        this.filters.options.site = this.claim.site.id;
        this.filters.selectedOptions.site = this.claim.site.id;
        this.filters.options.registration = this.claim.vehicle.plate;
        this.filters.selectedOptions.registration = this.claim.vehicle.plate;
        axios.get(`https://server.varsanpr.com/v2/vehicle/${this.claim.vehicle_id}/claims?claim_id=${this.claim.id}&client_id=${this.client_id}`, {
            headers: authHeader()
        })
            .then(response => {
                this.outstanding = response.data.claims;
                this.driveoff.amount = response.data.counts.driveoff;
                this.driveoff.value = parseFloat(response.data.values.driveoff).toFixed(2);
                this.nmop.amount = response.data.counts.nmop;
                this.nmop.value = parseFloat(response.data.values.nmop).toFixed(2);
                this.parking.amount = response.data.counts.parking;
                this.parking.value = parseFloat(response.data.values.parking).toFixed(2);
            })
            .catch(error => {
                // this.$error("Unable to load outstanding claims.", error);
            });

        if(this.claim?.site?.cameras?.length < 1){
            console.log(`No cameras, don't search`)
        }else{
            this.search(1, true);
        }
    },
    methods: {
        openEditModal(){
            this.make = (this.claim.override_mmc == 1 ? this.claim.vehicle_make : this.claim.make);
            this.model = (this.claim.override_mmc == 1 ? this.claim.vehicle_model : this.claim.model);
            this.colour = (this.claim.override_mmc == 1 ? this.claim.vehicle_colour : this.claim.colour);
            this.fuelType = (this.claim.override_mmc == 1 ? this.claim.vehicle_fueltype : this.claim.fuelType);
            $('#editModal').modal('toggle');
            $('.select2').select2();
        },
        edit(){
            if(this.creating) return;
            this.creating = true;
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/mmc`, {
                make: this.make,
                model: this.model,
                colour: this.colour,
                fuelType: this.fuelType,
                client_id: this.client_id
            }, {
                headers: authHeader()
            })
                .then(response => {
                    $('#editModal').modal('toggle');
                    this.$emit('changeMade');
                    this.$emit('mmcChange', {make: this.make, model: this.model, colour: this.colour, fuelType: this.fuelType});
                    this.$emit('reload', {});
                    this.creating = false;
                    this.$success("Changed Make, Model and Colour");
                })
                .catch(error => {
                    this.$error("Failed to update Make, Model and Colour", error);
                    this.creating = false;
                })
        },
        imageLoaded(){
            if(!this.popups.event.imageLoading) return;
            this.popups.event.imageLoading = false;
            this.magnify('event_image_popup', 3);
        },
        closeImage(){
            this.popups.event.showing = false;
            this.popups.event.imageUrl = "";
            this.popups.event.imageLoading = false;
        },
        showImage(event){
            if(this.popups.event.showing) return;
            this.popups.event.event = event;
            // this.popups.event.imageUrl = 'https://api.varsanpr.com/test/admin/events/' + event.id + '/image?token='+this.user.socket_token+'&site_id=' + event.site.id;
            this.popups.event.showing = true;
            this.popups.event.imageLoading = true;
        },
        magnify(imgID, zoom) {
            //https://www.w3schools.com/howto/howto_js_image_magnifier_glass.asp
            var img, glass, w, h, bw;
            img = document.getElementById(imgID);
            /*create magnifier glass:*/
            glass = document.createElement("DIV");
            glass.setAttribute("class", "img-magnifier-glass");
            /*insert magnifier glass:*/
            img.parentElement.insertBefore(glass, img);
            /*set background properties for the magnifier glass:*/
            glass.style.backgroundImage = "url('" + img.src + "')";
            glass.style.backgroundRepeat = "no-repeat";
            glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
            bw = 3;
            w = glass.offsetWidth / 2;
            h = glass.offsetHeight / 2;
            /*execute a function when someone moves the magnifier glass over the image:*/
            glass.addEventListener("mousemove", moveMagnifier);
            img.addEventListener("mousemove", moveMagnifier);
            /*and also for touch screens:*/
            glass.addEventListener("touchmove", moveMagnifier);
            img.addEventListener("touchmove", moveMagnifier);
            function moveMagnifier(e) {
                var pos, x, y;
                /*prevent any other actions that may occur when moving over the image*/
                e.preventDefault();
                /*get the cursor's x and y positions:*/
                pos = getCursorPos(e);
                x = pos.x;
                y = pos.y;
                glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
                /*prevent the magnifier glass from being positioned outside the image:*/
                if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
                if (x < w / zoom) {x = w / zoom;}
                if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
                if (y < h / zoom) {y = h / zoom;}
                /*set the position of the magnifier glass:*/
                glass.style.left = (x - w) + "px";
                glass.style.top = (y - h) + "px";
                /*display what the magnifier glass "sees":*/
                glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
            }
            function getCursorPos(e) {
                var a, x = 0, y = 0;
                e = e || window.event;
                /*get the x and y positions of the image:*/
                a = img.getBoundingClientRect();
                /*calculate the cursor's x and y coordinates, relative to the image:*/
                x = e.pageX - a.left;
                y = e.pageY - a.top;
                /*consider any page scrolling:*/
                x = x - window.pageXOffset;
                y = y - window.pageYOffset;
                return {x : x, y : y};
            }
        },
        pagiSearch(page){
            return this.search(page, true);
        },
        search(page, filtered = false){
            let url = `https://api.varsanpr.com/api/claims/${this.claim.id}/events`;
            let query = [{name: 'client_id', value: this.client_id}, {name: 'count', value: 25}, {name: 'page', value: page}];
            if(this.qc(this.filters.options.site)){
                query.push({name: "site", value: this.filters.options.site});
            }
            if(this.qc(this.filters.options.registration)){
                query.push({name: "registration", value: this.filters.options.registration});
            }
            if(this.qc(this.filters.options.camera)){
                query.push({name: "camera", value: this.filters.options.camera});
            }
            if(this.qc(this.filters.options.direction)){
                query.push({name: "direction", value: this.filters.options.direction});
            }
            if(this.qc(this.filters.options.status)){
                query.push({name: "status", value: this.filters.options.status});
            }
            if(this.qc(this.filters.options.start_date)){
                query.push({name: "start_date", value: this.filters.options.start_date});
            }
            if(this.qc(this.filters.options.end_date)){
                query.push({name: "end_date", value: this.filters.options.end_date});
            }
            if(this.qc(this.filters.options.start_time)){
                query.push({name: "start_time", value: this.filters.options.start_time});
            }
            if(this.qc(this.filters.options.end_time)){
                query.push({name: "end_time", value: this.filters.options.end_time});
            }

            if(query.length > 0){
                let queryString = "?";
                query.forEach((q) => {
                    queryString = `${queryString}${q.name}=${q.value}&`;
                });
                queryString = queryString.slice(0, -1);
                url = `${url}${queryString}`;
            }
            this.loading = true;
            axios.get(url, {
                headers: authHeader()
            })
                .then(response => {
                    response.data.events.forEach((event) => {
                        event.created_at = DateTime.fromSeconds(parseInt(event.created_at)).toFormat('dd/MM/yyyy HH:mm:ss');
                        event.io = "";
                        if(event.direction === 'forward'){
                            event.io = 'In';
                        }
                        if(event.direction === 'reverse'){
                            event.io = 'Out';
                        }
                        event.evidence = this.claim.events.filter((e) => {
                            return e.id == event.id;
                        }).length > 0;
                    });
                    this.events = response.data.events;
                    this.pagination.currPage = response.data.pagination.page;
                    this.pagination.totalResults = response.data.pagination.total;
                    this.pagination.from = 1 + (response.data.pagination.page -1) * response.data.pagination.showing;
                    this.pagination.to = 1 + response.data.pagination.page * response.data.pagination.showing;
                    if(this.pagination.to > this.pagination.totalResults){
                        this.pagination.to = this.pagination.totalResults;
                    }
                    this.pagination.perPage = response.data.pagination.showing;
                    this.pagination.lastPage = response.data.pagination.lastPage;

                    this.loading = false;
                })
                .catch(error => {
                    this.$error("Failed to load events", error);
                    this.loading = false;
                });
        },
        qc(val){
            if(val === null) return false;
            if(val === undefined) return false;

            if(typeof val === 'string'){
                if(val.length < 1) return false;
            }

            return true;
        },
        toggleFilters(){
            this.filters.open = !this.filters.open;
        },
        applyFilters(){
            this.filters.selectedOptions = _.cloneDeep(this.filters.options);
            this.search(1, true);
        },
        clearFilters(){
            this.filters.options = {
                direction: "",
                start_date: "",
                end_date: "",
                start_time: "",
                end_time: ""
            };
            this.filters.selectedOptions = _.cloneDeep(this.filters.options);
            this.search(1);
            $(".select2").select2();
        },
        grayscale(event){
            if(this.grayscaling) return;
            this.grayscaling = true;
            axios.post(`https://api.varsanpr.com/api/event/${event.id}/grayscale`, {
                client_id: this.client_id
            }, {
                responseType: 'arraybuffer',
                headers: authHeader()
            })
            .then(response => {
                let blob = new Blob([response.data], {type: 'application/pdf'});
                event.grayscaled = URL.createObjectURL(blob);
                this.objectUrls.push(event.grayscaled);
            })
            .catch(error => {
                this.$error("Failed to grayscale image", error);
            })
            .finally(() => {
                this.grayscaling = false;
            });
        },
        addEventToEvidence(event){
            if(!this.$can('claims-manage-team')) return;
            if(this.updatingEvidence) return;
            this.updatingEvidence = true;
            axios.put(`https://api.varsanpr.com/api/claims/${this.claim.id}/events`, {
                client_id: this.client_id,
                event_id: event.id
            }, {
                headers: authHeader()
            })
            .then(response => {
                event.evidence = true;
                this.$success("Added event to evidence");
                this.$emit('updateevents');
                this.$emit('changeMade');
            })
            .catch(error => {
                this.$error("Failed to add event to evidence", error);
            })
            .finally(() => {
                this.updatingEvidence = false;
            });
        },
        removeEventFromEvidence(event){
            if(!this.$can('claims-manage-team')) return;
            if(this.updatingEvidence) return;
            this.updatingEvidence = true;
            axios.delete(`https://api.varsanpr.com/api/claims/${this.claim.id}/events`, {
                data: {
                    client_id: this.client_id,
                    event_id: event.id
                },
                headers: authHeader()
            })
                .then(response => {
                    event.evidence = false;
                    this.$success("Removed event from evidence");
                    this.$emit('updateevents');
                    this.$emit('changeMade');
                })
                .catch(error => {
                    this.$error("Failed to remove event from evidence", error);
                })
                .finally(() => {
                    this.updatingEvidence = false;
                });
        }
    },
    computed: {
        selectedFilters: function(){
            let count = 0;
            if(this.filters.selectedOptions.registration.toString().length > 0) count++;
            if(this.filters.selectedOptions.site.toString().length > 0) count++;
            if(this.filters.selectedOptions.camera.toString().length > 0) count++;
            if(this.filters.selectedOptions.direction.toString().length > 0) count++;
            if(this.filters.selectedOptions.status.toString().length > 0) count++;
            if(this.filters.selectedOptions.start_date.toString().length > 0) count++;
            if(this.filters.selectedOptions.end_date.toString().length > 0) count++;
            if(this.filters.selectedOptions.start_time.toString().length > 0) count++;
            if(this.filters.selectedOptions.end_time.toString().length > 0) count++;
            return count;
        },
        optionsMatch: function (){
            return JSON.stringify(this.filters.options) === JSON.stringify(this.filters.selectedOptions);
        }
    }
}
</script>

<style scoped>
.receipt-footer {
    background-color: #f64747;
    text-align: center;
    min-height: 40px;
    max-height: 40px;
    height: 40px;
    line-height: 40px;
    color: white;
    font-size: 20px;
}
.receipt-footer.receipt-fee {
    background-color: #2abb9b
}
.receipt-body{
    flex-grow: 1;
    color: black;
    padding-left: 10px;
    padding-right: 10px;
}
.receipt-header{
    font-size: 20px;
    font-weight: bold;
    color: #0c0c0d;
    margin: 10px;
    text-align: center;
}
.receipt {
    width: 250px;
    background-color: #f5f6f8;

    max-height: 650px;
    display: flex;
    flex-direction: column;
    margin: 20px;
}
.subtotal {
    width: 200px;
    height: 40px;
    line-height: 40px;
    background-color: #68b7a1;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: white;
}
</style>
