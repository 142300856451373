<template>
    <div>
        <div class="row row-sm">
            <div class="col-lg-4 col-md-12 col-sm-12"></div>
            <div class="col-lg-8 col-md-12 col-sm-12">
                <button v-if="$can('claims-process')" class="btn btn-sm btn-primary ft-right" @click="openAddModal">Upload File</button>
            </div>
        </div>
        <div class="row">

            <div class="col-lg-4 col-md-12 col-sm-12">
                <p>Preview: <span v-if="selectedAsset !== null">{{selectedAsset.filename}}</span></p>
                <p class="tx-white tx-bold" v-if="selectedAsset == null">Please click on a file to view it!</p>
                
                <div v-show="!pixelating" style="max-height: 750px; width: auto;">
                    <!-- Generic Asset -->
                    <secure-image :id="'selectedAsset' + claim.id" v-viewer v-if="selectedAsset !== null && isImage && selectedAsset.trueType !== 'event_image'" :endpoint="'/api/claims/' + claim.id + '/evidence/'" :filename="selectedAsset.filename" :site_id="'' + claim.site_id" :client_id="'' + client_id" style="width: 100%; display: block;"></secure-image>

                    <!-- Video Asset -->
                    <secure-video v-if="selectedAsset !== null && isVideo" :endpoint="'/api/claims/' + claim.id + '/evidence/'" :filename="selectedAsset.filename" :site_id="'' + claim.site_id" :client_id="'' + client_id" style="width: 100%; display: block;"></secure-video>

                    <!-- Event Image -->
                    <secure-image :id="'selectedAssetImage' + claim.id" v-viewer v-if="selectedAsset !== null && isImage && selectedAsset.trueType === 'event_image'" :filename="selectedAsset.filename" :site_id="claim.site.id"  :endpoint="'/api/event/image/'" :storage_account="selectedAsset.storage_account" style="width: 100%; display: block;"></secure-image>

                    <button v-if="selectedAsset != null && !isPdf && !cropper && !isVideo && !pixelating" class="btn btn-sm btn-primary" @click="editImage">Edit</button>
                    <button v-if="selectedAsset != null && !isPdf && !cropper && !isVideo && !pixelating" class="btn btn-sm btn-primary" @click="pixelateImage">Pixelate</button>
                </div>
                
                <div v-show="pixelating" style="max-height: 750px; width: auto;">

                    <canvas id="c" width="750" height="500"></canvas>

                </div>
                <!-- PDF -->
                <iframe v-if="selectedAsset !== null && isPdf" :src="pdf_url" width="100%" height="750px" min-height="500px;"></iframe>
                <p v-if="selectedAsset != null && !isPdf && !isImage && !isVideo">Unfortunately this filetype is not supported in VARS. Please download it to view it.</p>
            </div>
            <div v-show="cropper" class="col-lg-8 col-md-12 col-sm-12">

                <button class="btn btn-sm btn-danger mg-b-sm" @click="cancelEdit">Cancel Edit</button>
                <button class="btn btn-sm btn-success mg-b-sm" @click="cropAndSave">Crop and Save</button>

                <br/>
                <br/>
                
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button class="btn btn-sm btn-primary" @click="rotate(rotateSpeed * -1)">Rotate Anti-Clockwise</button>
                        </div>
                        <input class="form-control" type="number" step="1" min="1" max="360" v-model="rotateSpeed">
                        <div class="input-group-append">
                            <button class="btn btn-sm btn-primary" @click="rotate(rotateSpeed)">Rotate Clockwise</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="pixelating" class="col-lg-8 col-md-12 col-sm-12">
                
                <button class="btn btn-sm btn-danger" style="margin-right: 10px;" @click="cancelPixelation">Cancel Edit</button>
                <button class="btn btn-sm btn-primary" :disabled="addingRectangle" style="margin-right: 10px;" @click="addRectangle">Add Rectangle</button>
                <button class="btn btn-sm btn-success" @click="pixelateAndSave">Pixelate and Save</button>

                <br/>
                <br/>

            </div>
            <div v-show="!cropper && !pixelating" class="col-lg-8 col-md-12 col-sm-12">

                <div v-if="selectedAsset?.type == 'till_receipt'" style="color: white; position: fixed; right: 0px; z-index: 100000; background-color: #000000; opacity: .8; padding: 25px; border: 1px solid white; bottom: 0px;">
                    <p><strong>Expected Value: </strong> {{Number.parseFloat(claim.value).toFixed(2)}} of {{claim.fuel}}</p>
                    <p><strong>Expected Litres: </strong> {{Number.parseFloat(claim.litres).toFixed(2)}}</p>
                    <p><strong>Expected Pump: </strong>{{ claim.pump }}</p>
                    <p v-if="claim.fuel_second !== null"><strong>Expected Second Value: </strong> {{Number.parseFloat(claim.value_second).toFixed(2)}} of {{claim.fuel_second}}</p>
                    <p v-if="claim.fuel_second !== null"><strong>Expected Litres: </strong> {{Number.parseFloat(claim.litres_second).toFixed(2)}}</p>
                    <p v-if="claim.fuel_second !== null"><strong>Expected Pump: </strong>{{ claim.pump_second }}</p>
                    <p><strong>Expected Fuel Type for Vehicle: </strong>{{claim.override_mmc == 1 ? claim.vehicle_fueltype : claim.fuelType}}</p>

                    <p><strong>Expected Date and Time:</strong> {{ incident_timestamp }}</p>
                </div>

                <div v-if="selectedAsset?.type == 'vehicle_image' || selectedAsset?.trueType == 'event_image'" style="color: white; position: fixed; right: 0px; z-index: 100000; background-color: #000000; opacity: .8; padding: 25px; border: 1px solid white; bottom: 0px;"> 
                    <p><strong>Expected Registration: </strong> {{claim.vehicle.plate}}</p>
                    <p><strong>Expected Make: </strong> {{vehicle_make}}</p>
                    <p><strong>Expected Model: </strong> {{vehicle_model}}</p>
                    <p><strong>Expected Colour: </strong> {{vehicle_colour}}</p>
                    <p v-if="claim.type == 'parking'"><strong>Expected Date and Time:</strong> {{ selectedAsset.created_at }}</p>
                    <p v-else><strong>Expected Date and Time:</strong> {{ incident_timestamp }}</p>
                    <p v-if="claim.type !== 'parking' && claim.type !== 'evparking'"><strong>Expected Pump: </strong>{{ claim.pump }}</p>
                    <p v-if="claim.type !== 'parking' && claim.type !== 'evparking' && claim.fuel_second !== null"><strong>Expected 2nd Pump: </strong>{{ claim.pump_second }}</p>
                    <p v-if="claim.type !== 'parking' && claim.type !== 'evparking'"><strong>Expected Fuel Type for Vehicle: </strong>{{claim.override_mmc == 1 ? claim.vehicle_fueltype : claim.fuelType}}</p>
                </div>

                <p v-if="selected > 0">{{selected}}/{{claim.assets.length}} files selected </p>
                <submit-button v-if="selected > 0" :background="'btn btn-sm btn-primary float-right'" :clicked="downloading" :text="'Download ' + selected + ' Items'" @click="downloadEvidence"></submit-button>
                <table class="table mg-b-0">
                    <thead>
                    <tr>
                        <th class="wd-5p">
                            <label class="ckbox mg-b-0">
                                <input v-model="allChecked" type="checkbox" @change="toggleAllCheck">
                                <span></span>
                            </label>
                        </th>
                        <th class="tx-10-force tx-mont tx-medium">Name</th>
                        <th class="tx-10-force tx-mont tx-medium">Size</th>
                        <th class="tx-10-force tx-mont tx-medium hidden-xs-down">Evidence Type</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="asset in claim.assets" :class="{'tx-bold tx-white': currentId === asset.id}">
                        <td class="valign-middle">
                            <label class="ckbox mg-b-0">
                                <input v-model="asset.checked" type="checkbox">
                                <span></span>
                            </label>
                        </td>
                        <td @click="selectAsset(asset)" style="cursor: pointer;">{{asset.filename}}</td>
                        <td @click="selectAsset(asset)" style="cursor: pointer;">{{ asset.size}} </td>
                        <td v-if="$can('claims-process')" style="cursor: pointer;">
                            <select v-model="asset.type" class="form-control" v-on:change="evidenceTypeChanged(asset)">
                                <option v-for="type in availableTypes" v-bind:value="type.id">{{type.name}}</option>
                            </select>
                        </td>
                        <td v-else>{{getType(asset.type)}}</td>
                        <td @click="selectAsset(asset)" style="cursor: pointer;"></td>
                    </tr>
                    <tr v-for="event in claim.events" :class="{'tx-bold tx-white': currentId === event.id}">
                        <td class="valign-middle"></td>
                        <td @click="selectEvent(event)" style="cursor: pointer;">{{event.image}}</td>
                        <td></td>
                        <td style="cursor: pointer;">Event Image</td>
                        <td @click="selectEvent(event)" style="cursor: pointer;"></td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div id="tillImageModal" v-if="popups.till.showing" class="modal fade effect-just-me show" style="display: block; padding-right: 17px; height: 100%; max-width: 100%;">
                <div class="modal-dialog modal-dialog-centered" style="height: 100% !important; max-width: 100%;" role="document">
                    <div class="modal-content bd-0 tx-14" style="height: 100%; max-width: 100%;">
                        <div class="modal-body pd-25" style="height: 100%; max-width: 100%;">
                            <div class="row" style="height: 100%; max-width: 100%;">
                                <div class="col-12" style="height: 100%; max-width: 100%;">
                                    <div class="img-magnifier-container" style="height: 100%; max-width: 100%;">
                                        <img :style="{'transform': `rotate(${imageRotation}deg)`}" v-show="!popups.till.imageLoading" crossorigin="anonymous" id="till_image_popup" :src="`${popups.till.imageUrl}`" @load="imageLoadedTill" style="height: 100%; max-height:100%; max-width: 100%;">
                                    </div>
                                    <div v-show="popups.till.imageLoading">
                                        <div class="d-flex ht-300 pos-relative align-items-center">
                                            <div class="sk-folding-cube">
                                                <div class="sk-cube1 sk-cube"></div>
                                                <div class="sk-cube2 sk-cube"></div>
                                                <div class="sk-cube4 sk-cube"></div>
                                                <div class="sk-cube3 sk-cube"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <button class="btn btn-danger tx-11 tx-uppercase" @click="closeImageTill" style="position: absolute; top: 12px; right: 25px; z-index: 10000; font-size: 15px; font-weight: bold;" ><i class="fa fa-times"></i></button>
                                    <button class="btn btn-primary tx-11 tx-uppercase" @click="increaseRotation" style="position: absolute; top: 75px; right: 25px; z-index: 10000; font-size: 15px; font-weight: bold;" ><i class="fa fa-redo"></i></button>
                                    <button class="btn btn-primary tx-11 tx-uppercase" @click="decreaseRotation" style="position: absolute; top: 75px; right: 75px; z-index: 10000; font-size: 15px; font-weight: bold;" ><i class="fa fa-undo"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- modal-dialog -->
            </div>

        </div>
        <div id="addModal" class="modal fade" style="display: none; padding-right: 17px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content bg-dark tx-white tx-size-sm">
                    <div class="modal-header bg-dark pd-x-20">
                        <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">Add Evidence to Claim</h6>
                        <button type="button" class="close tx-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body bg-dark tx-white pd-20">

                        <p class="tx-danger tx-bold tx-uppercase">Please make sure you verify the file before you upload it. If the file is suspicious, please ask the support team to verify it.</p>
                        <div class="row no-gutters">
                            <div class="col-12">
                                <div class="form-group custom-file">
                                    <input type="file" accept="image/*,video/*,audio/*,application/pdf,application/msword,application/zip" ref="file" id="evidence_file" @change="selectFile" class="custom-file-input">
                                    <label class="custom-file-label" style="background-color: transparent; color: white;">{{evidence_filename}}</label>
                                </div>
                            </div>
                            <div class="col-12 mg-t-10">
                                <div class="form-group mg-md-l--1">
                                    <label class="form-control-label mg-b-0-force">Evidence Type: <span class="tx-danger">*</span></label>
                                    <select v-model="addEvidence.type" class="form-control">
                                        <option v-for="type in availableTypes" v-bind:value="type.id">{{type.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 mg-t-10">
                                <submit-button :background="'btn btn-info'" :text="'Add Evidence'" :clicked="addEvidence.uploading" @click="uploadEvidence" />
                                <button class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            </div><!-- form-group -->
                        </div><!-- row -->

                    </div><!-- modal-body -->
                    <div class="modal-footer bg-dark tx-white">
                        <button id="closeOpenModal" type="button" class="btn btn-secondary tx-size-xs" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div><!-- modal-dialog -->
        </div>

        <div v-if="popups.till.showing" @click="closeImageTill" class="modal-backdrop fade show"></div>
    </div>
</template>

<script>
import SecureImage from "@/components/SecureImage";
import SubmitButton from "@/components/SubmitButton";
import pdf from 'vue-pdf';
import Swal from "sweetalert2";
import saveAs from 'file-saver';
import 'viewerjs/dist/viewer.css'
import { directive as viewer } from "v-viewer"
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import JSZip from 'jszip';
import axios from 'axios';
import authHeader from '@/services/auth-header';
import { DateTime } from "luxon";
import SecureVideo from "../../../components/SecureVideo.vue";
import "jimp";

export default {
    name: "ClaimEvidence",
    directives: {
      viewer: viewer({
        debug: false,
      }),
    },
    components: {
        SecureImage,
        SubmitButton,
        pdf,
        SecureVideo
    },
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            imageRotation: 0,
            allChecked: false,
            selectedAsset: null,
            popups: {
                till: {
                    showing: false,
                    imageUrl: "",
                    imageLoading: false,
                    asset_id: null
                }
            },
            evidence_file: null,
            addEvidence: {
                type: "vehicle_image",
                uploading: false
            },
            pdf_url: null,
            currentPage: 1,
            pageCount: 0,
            availableTypes: [
                {
                    id: "notice",
                    name: "Notice to Keeper"
                },
                {
                    id: "vehicle_image",
                    name: "Vehicle Image"
                },
                {
                    id: "till_receipt",
                    name: "Till Receipt"
                },
                {
                    id: "other",
                    name: "Other Evidence"
                },
                {
                    id: "additional_information",
                    name: "Additional Information"
                },
                {
                    id: "dvla_response",
                    name: "DVLA Response"
                },
                {
                    id: "dvla_enquiry",
                    name: "DVLA Enquiry Form"
                },
                {
                    id: "transfer_of_liability",
                    name: "Transfer of Liability"
                },
                {
                    id: "signature",
                    name: "Customer Signature"
                },
                {
                    id: "customer",
                    name: "Image of Customer"
                },
                {
                    id: "SOFTDELETED",
                    name: "DELETE"
                }
            ],
            downloading: false,
            currentRotation: 0,
            cropper: null,
            rotateSpeed: 1,
            pixelating: false,
            rectangles: [],
            canvas: null,
            addingRectangle: false,
            isDown: false,
            origX: null,
            origY: null,
            drawRect: null,
            widthAspectRatio: 1,
            heightAspectRatio: 1,
            finalHeight: 0,
            finalWidth: 0,
        }
    },
    mounted() {
        document.addEventListener('keydown', (e) => {
            if(e.code === 'Escape'){
                this.closeImageTill();
                $('#closeOpenModal').click();
            }
        });
        this.getEvidenceTypes();
    },
    beforeRouteLeave(to, from, next){
        if(this.pdf_url){
            window.URL.revokeObjectURL(this.pdf_url);
        }
        document.removeEventListener('keydown', (e) => {
            if(e.code === 'Escape'){
                this.closeImageTill();
                $('#closeOpenModal').click();
            }
        });
        next();
    },
    methods: {
        getEvidenceTypes(){
            axios.get(`https://api.varsanpr.com/api/claims/evidencetypes`, {
                headers: authHeader()
            })
            .then(response => {
                let availableTypes = [
                    {
                        id: "SOFTDELETED",
                        name: "DELETE"
                    }
                ];
                response.data.types.forEach((type) => {
                    availableTypes.push({
                        id: type.system_type,
                        name: type.type
                    });
                });

                this.availableTypes = availableTypes;
            })
        },  
        editImage(){
            let image;
            if(this.selectedAsset !== null && this.isImage && this.selectedAsset.trueType !== 'event_image'){
                image = document.getElementById('selectedAsset' + this.claim.id);
            }else{
                image = document.getElementById('selectedAssetImage' + this.claim.id);
            }
            console.log(image);
            this.cropper = new Cropper(image, {
                viewMode: 2,
                rotatable: true,
                scalable: true,
                ready: function () {
                    var cropper = this.cropper;
                    var containerData = cropper.getContainerData();
                    var cropBoxData = cropper.getCropBoxData();
                    var aspectRatio = cropBoxData.width / cropBoxData.height;
                    var newCropBoxWidth;

                    if (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio) {
                        newCropBoxWidth = cropBoxData.height * ((minAspectRatio + maxAspectRatio) / 2);

                        cropper.setCropBoxData({
                        left: (containerData.width - newCropBoxWidth) / 2,
                        width: newCropBoxWidth
                        });
                    }
                },
                cropmove: function () {
                    var cropper = this.cropper;
                    var cropBoxData = cropper.getCropBoxData();
                    var aspectRatio = cropBoxData.width / cropBoxData.height;

                    if (aspectRatio < minAspectRatio) {
                        cropper.setCropBoxData({
                        width: cropBoxData.height * minAspectRatio
                        });
                    } else if (aspectRatio > maxAspectRatio) {
                        cropper.setCropBoxData({
                        width: cropBoxData.height * maxAspectRatio
                        });
                    }
                },
            });
        },
        cropAndSave(){
            this.cropper.getCroppedCanvas({
                fillColor: '#fff'
            }).toBlob((blob) => {
                // this.uploadImage(blob);

                let formData = new FormData();
                formData.append("file", blob, "cropped_" + this.selectedAsset.filename);
                formData.append("client_id", this.client_id);
                formData.append("type", this.selectedAsset.type);
                axios.put(`https://api.varsanpr.com/api/claims/evidence/${this.claim.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        ...authHeader()
                    }
                })
                .then(response => {
                    this.$success("Uploaded evidence file!");
                    axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${this.selectedAsset.filename}`, {
                        type: 'other',
                        client_id: this.client_id
                    }, {
                        headers: authHeader()
                    })
                    .then(response => {
                        if(response.data.success){
                            this.$success("Successfully updated evidence type!");
                            // this.$emit('refresh');
                        }
                        this.$emit('changeMade');
                        this.$emit("reload");
                        this.cropper.destroy();
                        this.cropper = null;
                    })
                    .catch(error => {
                        this.$error("Failed to update evidence type.", error);
                    });
                })
                .catch(error => {
                    this.$error("Failed to upload evidence file.", error);
                })
                .finally(() => {
                    this.addEvidence.uploading = false;
                })

            }, "image/jpeg");
        },
        rotate(val){
            this.cropper.rotate(val);
        },
        cancelEdit(){
            this.cropper?.destroy();
            this.cropper = null;
        },
      showViewer(){
        console.log('Show Viewer');
        const viewer = this.$el.querySelector('.images').$viewer;
        viewer.show();
      },
      rotateClockwise(){
          let current = this.currentRotation;
          current += 90;
          if(current > 360){
              current -= 360;
          }
          this.currentRotation = current;
        },
        rotateAntiClockwise(){
            let current = this.currentRotation;
            current -= 90;
            if(current > -360){
                current += 360;
            }
            this.currentRotation = current;
        },
        getType(id){
            return (this.availableTypes.filter((t) => {
                return t.id === id;
            })[0] || {name: ""}).name
        },
        increaseRotation(){
            this.imageRotation += 90;
        },
        decreaseRotation(){
            this.imageRotation -= 90;
        },
        evidenceTypeChanged(asset){
            if(asset.type === 'SOFTDELETED'){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "This will delete the evidence and is not reversible?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${asset.filename}`, {
                            type: asset.type,
                            client_id: this.client_id
                        }, {
                            headers: authHeader()
                        })
                            .then(response => {
                                if(response.data.success){
                                    this.$success("Successfully updated evidence type!");
                                    this.$emit('changeMade');
                                    // this.$emit('refresh');
                                }
                            })
                            .catch(error => {
                                this.$error("Failed to update evidence type.", error);
                            });
                    }else{
                        asset.type = asset.trueType;
                        $('.select2').select2();
                    }
                })
            }else{
                axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${asset.filename}`, {
                    type: asset.type,
                    client_id: this.client_id
                }, {
                    headers: authHeader()
                })
                    .then(response => {
                        if(response.data.success){
                            this.$success("Successfully updated evidence type!");
                            this.$emit('changeMade');
                            // this.$emit('refresh');
                        }
                    })
                    .catch(error => {
                        this.$error("Failed to update evidence type.", error);
                    });
            }
        },
        nextPage(){
            if(this.currentPage < this.pageCount){
                this.currentPage++;
            }
        },
        previousPage(){
            if(this.currentPage > 1){
                this.currentPage--;
            }
        },
        downloadPdf(){
            if(!this.isPdf){
                this.pdf_url = "";
                return;
            }
            if(this.pdf_url){
                window.URL.revokeObjectURL(this.pdf_url);
            }
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${this.selectedAsset.filename}?site_id=${this.claim.site.id}&storage_account=${this.selectedAsset.storage_account || 1}&client_id=${this.client_id}`, {
                responseType: 'arraybuffer',
                headers: authHeader()
            })
            .then(response => {
                let blob = new Blob([response.data], {type: 'application/pdf'});
                this.pdf_url = window.URL.createObjectURL(blob);
            })
            .catch(error => {
                console.log(error);
                this.pdf_url = "";
            })
        },
        selectFile(event){
            this.evidence_file = event.target.files[0];
        },
        uploadEvidence(){
            if(this.addEvidence.uploading) return;
            
            this.addEvidence.uploading = true;
            let formData = new FormData();
            formData.append("file", this.evidence_file);
            formData.append("client_id", this.client_id);
            formData.append("type", this.addEvidence.type);
            axios.put(`https://api.varsanpr.com/api/claims/evidence/${this.claim.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...authHeader()
                }
            })
            .then(response => {
                this.$success("Uploaded evidence file!");
                this.$emit("newEvidence", response.data.assets);
                this.$emit('changeMade');
                $('#closeOpenModal').click();
            })
            .catch(error => {
                this.$error("Failed to upload evidence file.", error);
            })
            .finally(() => {
                this.addEvidence.uploading = false;
            })
        },
        openAddModal(){
            $('#addModal').modal('toggle');
            $('.select2').select2();
        },
        toggleAllCheck(){
            this.claim.assets.forEach((asset) => {
                asset.checked = this.allChecked;
            });
        },
        selectAsset(asset){
            this.selectedAsset = asset;
            console.log(this.selectedAsset);
            this.downloadPdf();
            this.currentRotation = 0;
            this.currentPage = 1;
        },
        selectEvent(event){
            this.selectedAsset = {
                checked: false,
                claim_id: this.claim.id,
                filename: event.image,
                id: event.id,
                trueType: "event_image",
                type: "vehicle_image",
                storage_account: event.storage_account,
                created_at: DateTime.fromSeconds(event.created_at).toFormat('dd/MM/yyyy HH:mm:ss')
            };
            this.downloadPdf();
        },
        closeImageTill(){
            this.popups.till.showing = false;
            this.popups.till.imageUrl = "";
            this.popups.till.imageLoading = false;
        },
        showImageTill(id){
            this.popups.till.imageUrl = 'https://api.varsanpr.com/test/admin/claims/tillreceipt/' + id + '?token='+this.user.socket_token+'&site_id=' + this.claim.site_id;
            this.popups.till.showing = true;
            this.popups.till.imageLoading = true;
            this.popups.till.asset_id = id;
        },
        magnify(imgID, zoom) {
            //https://www.w3schools.com/howto/howto_js_image_magnifier_glass.asp
            var img, glass, w, h, bw;
            img = document.getElementById(imgID);
            /*create magnifier glass:*/
            glass = document.createElement("DIV");
            glass.setAttribute("class", "img-magnifier-glass");
            /*insert magnifier glass:*/
            img.parentElement.insertBefore(glass, img);
            /*set background properties for the magnifier glass:*/
            glass.style.backgroundImage = "url('" + img.src + "')";
            glass.style.backgroundRepeat = "no-repeat";
            glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
            bw = 3;
            w = glass.offsetWidth / 2;
            h = glass.offsetHeight / 2;
            /*execute a function when someone moves the magnifier glass over the image:*/
            glass.addEventListener("mousemove", moveMagnifier);
            img.addEventListener("mousemove", moveMagnifier);
            /*and also for touch screens:*/
            glass.addEventListener("touchmove", moveMagnifier);
            img.addEventListener("touchmove", moveMagnifier);
            function moveMagnifier(e) {
                var pos, x, y;
                /*prevent any other actions that may occur when moving over the image*/
                e.preventDefault();
                /*get the cursor's x and y positions:*/
                pos = getCursorPos(e);
                x = pos.x;
                y = pos.y;
                glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
                /*prevent the magnifier glass from being positioned outside the image:*/
                if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
                if (x < w / zoom) {x = w / zoom;}
                if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
                if (y < h / zoom) {y = h / zoom;}
                /*set the position of the magnifier glass:*/
                glass.style.left = (x - w) + "px";
                glass.style.top = (y - h) + "px";
                /*display what the magnifier glass "sees":*/
                glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
            }
            function getCursorPos(e) {
                var a, x = 0, y = 0;
                e = e || window.event;
                /*get the x and y positions of the image:*/
                a = img.getBoundingClientRect();
                /*calculate the cursor's x and y coordinates, relative to the image:*/
                x = e.pageX - a.left;
                y = e.pageY - a.top;
                /*consider any page scrolling:*/
                x = x - window.pageXOffset;
                y = y - window.pageYOffset;
                return {x : x, y : y};
            }
        },
        imageLoadedTill(){
            this.popups.till.imageLoading = false;
            this.magnify('till_image_popup', 3);
        },
        async downloadEvidence(){

            if(this.downloading) return;

            this.downloading = true;
            let selectedAssets = this.claim.assets.filter((a) => {
                return a.checked === true;
            });

            if(selectedAssets.length === 0){
                console.log("No assets selected");
                this.downloading = false;
                return;
            }

            if(selectedAssets.length === 1){
                console.log(selectedAssets[0].filename);
                axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${selectedAssets[0].filename}?client_id=${this.client_id}`, {
                    responseType: 'arraybuffer',
                    headers: authHeader()
                })
                .then((response) => {
                    let blob = new Blob([response.data]);
                    saveAs(blob, selectedAssets[0].filename);
                    this.downloading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.downloading = false;
                });
            }else{
                let zip = new JSZip();

                for await (let asset of selectedAssets){

                    let response = await axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${asset.filename}?client_id=${this.client_id}`, {
                        responseType: 'arraybuffer',
                        headers: authHeader()
                    });

                    zip.file(asset.filename, response.data);

                }

                await zip.generateAsync({type:"blob"})
                .then((content) => {
                    saveAs(content, "evidence.zip");
                    this.downloading = false;
                });
            }

            // selectedAssets.forEach((asset) => {
            //     query.push({name: 'asset_id', value: asset.id});
            // });
            // if(query.length > 0){
            //     let queryString = "?";
            //     query.forEach((q) => {
            //         queryString = `${queryString}${q.name}=${q.value}&`;
            //     });
            //     queryString = queryString.slice(0, -1);
            //     url = `${url}${queryString}`;
            // }
            // axios.get(url, {
            //     responseType: 'arraybuffer'
            // })
            // .then(response => {
            //     saveAs(new Blob([response.data]), 'evidence.zip');
            //     this.downloading = false;
            // })
            // .catch(error => {
            //     this.downloading = false;
            //     this.$error("Failed to download evidence.", error);
            // })

        },
        pixelateImage(){
            this.pixelating = true;
            this.rectangles = [];
            if(!this.canvas){
                this.canvas = new fabric.Canvas('c');
            }

            let selectedImage;
            if(this.selectedAsset !== null && this.isImage && this.selectedAsset.trueType !== 'event_image'){
                selectedImage = document.getElementById('selectedAsset' + this.claim.id);
            }else{
                selectedImage = document.getElementById('selectedAssetImage' + this.claim.id);
            }

            let img = new Image();
            img.onload = () => {
                let maxWidth = 750;
                let maxHeight = 500;

                let imgWidth = img.width;
                let imgHeight = img.height;

                console.log(`Image Dimensions: `, imgWidth, imgHeight);

                let widthAspectRatio = maxWidth / imgWidth;
                let heightAspectRatio = maxHeight / imgHeight;

                console.log(`Aspect Ratios: `, widthAspectRatio, heightAspectRatio);

                this.widthAspectRatio = widthAspectRatio;
                this.heightAspectRatio = heightAspectRatio;

                let finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);

                let finalHeight = imgHeight * finalAspectRatio;
                let finalWidth = imgWidth * finalAspectRatio;

                this.finalHeight = finalHeight;
                this.finalWidth = finalWidth;

                let imgTop = 0;

                let imgLeft = 0;

                let nsgImage = new fabric.Image(img).scale(finalAspectRatio);
                nsgImage.set({left: imgLeft, top: imgTop});

                this.canvas.setBackgroundImage(nsgImage, () => this.canvas.renderAll());

                this.canvas.setWidth(finalWidth);
                this.canvas.setHeight(finalHeight);
            }
            img.src = selectedImage.src;
        },
        addRectangle(){
            if(this.addingRectangle){
                this.addingRectangle = false;
                return;
            }
            this.addingRectangle = true;
            this.canvas.on('mouse:down', (o) => {
                this.isDown = true;
                var pointer = this.canvas.getPointer(o.e);
                this.origX = pointer.x;
                this.origY = pointer.y;
                var pointer = this.canvas.getPointer(o.e);
                this.drawRect = new fabric.Rect({
                    left: this.origX,
                    top: this.origY,
                    originX: 'left',
                    originY: 'top',
                    width: pointer.x-this.origX,
                    height: pointer.y-this.origY,
                    angle: 0,
                    fill: 'rgba(0,0,0,0.2)',
                    transparentCorners: false
                });
                this.rectangles.push(this.drawRect);
                this.canvas.add(this.drawRect);
            });

            this.canvas.on('mouse:move', (o) => {
                if(!this.isDown) return;
                var pointer = this.canvas.getPointer(o.e);

                if(this.origX>pointer.x){
                    this.drawRect.set({ left: Math.abs(pointer.x) });
                }
                if(this.origY>pointer.y){
                    this.drawRect.set({ top: Math.abs(pointer.y) });
                }

                this.drawRect.set({ width: Math.abs(this.origX - pointer.x) });
                this.drawRect.set({ height: Math.abs(this.origY - pointer.y) });

                this.canvas.renderAll();
            });

            this.canvas.on('mouse:up', (o) => {
                this.isDown = false;
                this.canvas.off('mouse:down');
                this.canvas.off('mouse:move');
                this.canvas.off('mouse:up');
                this.addingRectangle = false;

                console.log(this.rectangles);
            })
        },
        async pixelateAndSave(){
            // Using the rectangles stored in this.rectangles, use jimp to apply a pixelation to those sections of the image
            // Then save the image

            let selectedImage;
            if(this.selectedAsset !== null && this.isImage && this.selectedAsset.trueType !== 'event_image'){
                selectedImage = document.getElementById('selectedAsset' + this.claim.id);
            }else{
                selectedImage = document.getElementById('selectedAssetImage' + this.claim.id);
            }

            await Jimp.read(selectedImage.src)
                .then(async (jimpImage) => {

                    // Check aspect ratio
                    console.log(jimpImage.bitmap.width, jimpImage.bitmap.height);
                    let widthAspectRatio = jimpImage.bitmap.width / this.finalWidth;
                    let heightAspectRatio = jimpImage.bitmap.height / this.finalHeight;
                    console.log(widthAspectRatio, heightAspectRatio);

                    for await (let rect of this.rectangles){

                        let x = rect.left * widthAspectRatio;
                        let y = rect.top * heightAspectRatio;
                        let width = rect.width * widthAspectRatio;
                        let height = rect.height * heightAspectRatio;
                        // width = width * 2
                        console.log(`Aspect Ratio:`, widthAspectRatio, heightAspectRatio);

                        console.log(`Rect: `, rect.left, rect.top, rect.width, rect.height);
                        console.log(`Final: `, x, y, width, height);

                        await jimpImage.pixelate(
                            50,
                            x,
                            y,
                            width,
                            height
                        );
                    }

                    jimpImage.getBuffer(Jimp.MIME_JPEG, (err, buffer) => {
                        if(err){
                            console.error(err);
                            return;
                        }

                        let blob = new Blob([buffer], {type: 'image/jpeg'});

                        let formData = new FormData();
                        formData.append("file", blob, "pixelated_" + this.selectedAsset.filename);
                        formData.append("client_id", this.client_id);
                        formData.append("type", this.selectedAsset.type);
                        axios.put(`https://api.varsanpr.com/api/claims/evidence/${this.claim.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                ...authHeader()
                            }
                        })
                        .then(response => {
                            this.$success("Uploaded evidence file!");
                            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${this.selectedAsset.filename}`, {
                                type: 'other',
                                client_id: this.client_id
                            }, {
                                headers: authHeader()
                            })
                            .then(response => {
                                if(response.data.success){
                                    this.$success("Successfully updated evidence type!");
                                    // this.$emit('refresh');
                                }
                                this.$emit('changeMade');
                                this.$emit("reload");
                            })
                            .catch(error => {
                                this.$error("Failed to update evidence type.", error);
                            });
                        })
                        .catch(error => {
                            this.$error("Failed to upload evidence file.", error);
                        })
                        .finally(() => {
                            this.addEvidence.uploading = false;
                            this.canvas.clear();
                            this.rectangles = [];
                            this.addingRectangle = false;
                            this.origX = null;
                            this.origY = null;
                            this.drawRect = null;
                            this.isDown = false;
                            this.widthAspectRatio = 1;
                            this.heightAspectRatio = 1;
                            this.finalHeight = 0;
                            this.finalWidth = 0;
                            this.pixelating = false;
                        })

                    });

                })
                .catch(err => {
                    console.error(err);
                })
        },
        cancelPixelation(){
            this.pixelating = false;
        }

    },
    computed: {
        evidence_filename: function(){
            if(this.evidence_file == null){
                return "Select File";
            }
            if(this.evidence_file == null){
                return "File Selected";
            }
            return this.evidence_file.name;
        },
        selected: function(){
            console.log(this.claim.assets.filter((a) => {return a.checked === true;}));
            return this.claim.assets.filter((a) => {
                return a.checked === true;
            }).length;
        },
        currentId: function(){
            if(this.selectedAsset){
                return this.selectedAsset.id;
            }
            return -1;
        },
        currentFileType: function(){
            if(this.selectedAsset){
                return this.selectedAsset.filename.slice((this.selectedAsset.filename.lastIndexOf(".") - 1 >>> 0) + 2);
            }
            return "";
        },
        isImage: function(){
            switch(this.currentFileType.toLowerCase()){
                case 'png':
                case 'jpg':
                case 'gif':
                case 'jpeg':
                case 'svg':
                case 'webp':
                case 'apng':
                    return true;
                default:
                    return false;
            }
        },
        isVideo: function(){
            switch(this.currentFileType.toLowerCase()){
                case 'mp4':
                case 'webm':
                case 'ogg':
                    return true;
                default:
                    return false;
            }
        },
        isPdf: function(){
            return this.currentFileType === 'pdf';
        },
        incident_timestamp: function(){
            if(this.claim.incident_timestamp){
                return DateTime.fromSeconds(this.claim.incident_timestamp).toLocaleString(DateTime.DATETIME_MED);
            }
            return "";
        },
        vehicle_make: function(){
            if(this.claim.override_mmc == 1){
                return this.claim.vehicle_make;
            }
            return this.claim.make;
        },
        vehicle_model: function(){
            if(this.claim.override_mmc == 1){
                return this.claim.vehicle_model;
            }
            return this.claim.model;
        },
        vehicle_colour: function(){
            if(this.claim.override_mmc == 1){
                return this.claim.vehicle_colour;
            }
            return this.claim.colour;
        },
        vehicle_fueltype: function(){
            if(this.claim.override_mmc == 1){
                return this.claim.vehicle_fueltype;
            }
            return this.claim.fuelType;
        },
    }
}
</script>
