<template>
    <div style="width: 100%;">
        <!-- Alert -->
        <div class="alert alert-warning" role="alert">
            <div class="d-flex align-items-center justify-content-start">
              <i class="icon ion-ios-checkmark alert-icon tx-32 mg-t-5 mg-xs-t-0"></i>
              <span><strong>Warning!</strong> Please take your time and ensure the details you check are correct to the best of your knowledge!</span>
            </div><!-- d-flex -->
        </div><!-- alert -->

        <div class="text-white" style="width: 100%;">

            <!-- Step 1: Verify the fuel type, litres and value of the till receipt -->
            <div v-if="step == 1">
                <p><strong>Verify the fuel type, litres and value of the till receipt</strong></p>

                <div class="row">

                    <div class="col-6">

                        <SecureImage v-viewer v-if="till_receipt !== null && till_file_type !== 'pdf'" :filename="till_receipt.filename" :client_id="client_id" :claim_id="claim.id" :type="'till_receipt'" :site_id="claim.site_id" :storage_account="till_receipt.storage_account" style="max-height: 75vh; width: auto; max-width: 100%;" :endpoint="'/api/claims/' + claim.id + '/evidence/'" :class="{'rotate-90': currentRotation == 90, 'rotate-180': currentRotation == 180, 'rotate-270': currentRotation == 270}" />

                        <div v-if="till_receipt !== null">
                            <button class="btn btn-primary btn-sm" @click="rotate">Rotate</button>
                        </div>

                        <div v-if="till_receipt !== null && till_file_type == 'pdf'" style="max-height: 75vh; width: auto; max-width: 100%;">
                            <iframe :src="pdf_url" width="100%" style="height: 75vh;"></iframe>
                        </div>

                        <div v-if="till_receipt == null" class="alert alert-danger" role="alert">
                            <div class="d-flex align-items-center justify-content-start">
                              <i class="icon ion-ios-checkmark alert-icon tx-32 mg-t-5 mg-xs-t-0"></i>
                              <span><strong>Problem!</strong> The system is unable to detect a valid till receipt attached to this claim. </span>
                            </div><!-- d-flex -->
                        </div><!-- alert -->

                    </div>
                    <div class="col-6">

                        <div class="form-group">

                            <label class="form-control-label">Can you read the receipt? <span class="text-danger">*</span></label>
                            <select @change="$emit('pendingLock', receipt.can_read == 'No')" class="form-control form-control-dark" v-model="receipt.can_read">
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>

                        </div>

                        <p v-if="receipt.can_read == 'No'" class="text-warning">The uploaded evidence tab has been unlocked. Please review the evidence there and check if a valid receipt is available. If there is no receipt, please press the awaiting evidence button to send it to awaiting evidence.</p>

                        <button v-if="receipt.can_read == 'No'" class="btn btn-primary btn-warning" @click="awaitingEvidence('till_receipt')">Awaiting Evidence</button>
                        <!-- <button v-if="receipt.can_read == 'No'" class="btn btn-primary btn-primary" @click="$emit('reload')">Refresh</button> -->

                        <div v-else-if="receipt.can_read == 'Yes'">

                            <div class="form-group">

                                <label class="form-control-label">Fuel Type <span class="text-danger">*</span></label>
                                <select class="form-control form-control-dark" v-model="receipt.fuel_type">
                                    <option value="Petrol">Petrol</option>
                                    <option value="Diesel">Diesel</option>
                                    <option value="LPG">LPG</option>
                                    <option value="AdBlue">AdBlue</option>
                                    <option value="Red Diesel">Red Diesel</option>
                                </select>
    
                            </div>
    
                            <div class="form-group">
    
                                <label class="form-control-label">Litres <span class="text-danger">*</span></label>
                                <input  onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="receipt.litres" type="number" step="0.01" class="form-control form-control-dark" />
    
                            </div>
    
                            <div class="form-group">
                                    
                                    <label class="form-control-label">Value <span class="text-danger">*</span></label>
                                    <input onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="receipt.value" type="number" step="0.01" class="form-control form-control-dark" />
    
                            </div>
    
                            <div class="form-group">
    
                                <label class="form-control-label">Pump <span class="text-danger">*</span></label>
                                <input onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="receipt.pump" type="number" min="0" max="30" class="form-control form-control-dark" />
    
                            </div>
    
                            <br/>
    
                            <div class="form-group">
    
                                <label class="form-control-label">Second Fuel Type <span class="text-danger">*</span></label>
                                <select class="form-control form-control-dark" v-model="receipt.fuel_second">
                                    <option value="Petrol">Petrol</option>
                                    <option value="Diesel">Diesel</option>
                                    <option value="LPG">LPG</option>
                                    <option value="AdBlue">AdBlue</option>
                                    <option value="Red Diesel">Red Diesel</option>
                                    <option :value="-1">- NONE -</option>
                                </select>
    
                            </div>
    
                            <div v-if="receipt.fuel_second != -1" class="form-group">
    
                                <label class="form-control-label">Second Litres</label>
                                <input onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="receipt.litres_second" type="number" step="0.01" class="form-control form-control-dark" />
    
                            </div>
    
                            <div v-if="receipt.fuel_second != -1" class="form-group">
                                    
                                    <label class="form-control-label">Second Value</label>
                                    <input onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="receipt.value_second" type="number" step="0.01" class="form-control form-control-dark" />
    
                            </div>
    
                            <div v-if="receipt.fuel_second != -1" class="form-group">
    
                                <label class="form-control-label">Second Pump</label>
                                <input onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="receipt.pump_second" type="number" min="0" max="30" class="form-control form-control-dark" />
    
                            </div>
    
                            <div class="form-group">
    
                                <p>In order to continue, you must explain why you are happy with the information.</p>
    
                                <label class="form-control-label">Justification <span class="text-danger">*</span></label>
                                <textarea onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="receipt.justification" class="form-control form-control-dark" rows="3"></textarea>
                                <span class="text-muted">{{receipt.justification?.length || 0}}/10 Characters</span>
    
                            </div>
    
                            <p class="text-warning" v-if="receipt.hadMismatch">The values you have entered do not match the ones provided by the site. Please review the details you have entered and the comments provided by the site. The site may have deducted any payments already made from the total owed.</p>
    
                            <label v-if="receipt.hadMismatch" class="ckbox">
                                <input v-model="receipt.acceptance" type="checkbox">
                                <span>I am aware they do not match and the values I have entered are correct</span>
                            </label>
    
                            <button @click="verifyReceipt()" class="btn btn-sm btn-primary">Verify</button>

                        </div>

                    </div>

                </div>
    
            </div>  

            <!-- Step 2: Check that the vehicle registration matches the one in the image -->
            <div v-if="step == 2">
                <p><strong>Check that the vehicle registration matches the one in the image</strong></p>

                <div class="row">
                    <div class="col-6">

                        <secure-image v-viewer v-if="vehicle_image !== null && vehicle_file_type !== 'pdf'" :filename="vehicle_image.filename" :site_id="claim.site.id" :endpoint="'/api/claims/' + claim.id + '/evidence/'" :client_id="client_id" :storage_account="vehicle_image.storage_account" style="max-height: 75vh; width: auto; max-width: 100%; height: auto;" height="auto"
                        :class="{'rotate-90': currentRotation == 90, 'rotate-180': currentRotation == 180, 'rotate-270': currentRotation == 270}">

                            <p> <i class="fa-regular fa-triangle-exclamation text-danger"></i> There was an issue loading the image.</p>

                        </secure-image>

                        <div v-if="vehicle_image !== null">
                            <button class="btn btn-primary btn-sm" @click="rotate">Rotate</button>
                        </div>

                        <div v-if="vehicle_image !== null && vehicle_file_type == 'pdf'" style="max-height: 75vh; width: auto; max-width: 100%;">
                            <iframe :src="vehicle_pdf_url" width="100%" style="height: 75vh;"></iframe>
                        </div>
    
                        <div v-else class="alert alert-danger" role="alert">
                            <div class="d-flex align-items-center justify-content-start">
                              <i class="icon ion-ios-checkmark alert-icon tx-32 mg-t-5 mg-xs-t-0"></i>
                              <span><strong>Problem!</strong> The system is unable to detect a valid event image attached to this claim. </span>
                            </div><!-- d-flex -->
                        </div><!-- alert -->
    
                    </div>
                    <div class="col-6">
    
                        <div class="form-group">
    
                            <label class="form-control-label">Can you see a vehicle in this image? <span class="text-danger">*</span></label>
                            <select class="form-control form-control-dark" v-model="vehicle.vehicle_in_image">
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
    
                        </div>

                        <div v-if="vehicle.vehicle_in_image === 'No'">

                            <button class="btn btn-primary btn-warning" @click="awaitingEvidence('vehicle_image')">Awaiting Evidence</button>

                            <label v-if="vehicle.vehicle_in_image === 'No'" class="ckbox">
                                <input v-model="vehicle.no_image_available" type="checkbox">
                                <span>I have contacted the site and they have confirmed that they are unable to provide a vehicle image. (Please ensure relevant tickets or calls are referenced in the claim notes as these will be audited)</span>
                            </label>

                        </div>
    
                        <div v-if="vehicle.vehicle_in_image === 'Yes'">
    
                            <div class="form-group">
    
                                <label class="form-control-label">Can you make out the registration number? <span class="text-danger">*</span></label>
                                <select class="form-control form-control-dark" v-model="vehicle.registration_visible">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
        
                            </div>
    
                        </div>
    
                        <div v-if="vehicle.registration_visible == 'Yes'">
    
                            <div class="form-group">
    
                                <label class="form-control-label">What is the registration number in the image? <span class="text-danger">*</span></label>
                                <input onpaste="return false;" ondrop="return false;" autocomplete="off" minlength="3" maxlength="11" v-model="vehicle.registration" type="text" class="form-control form-control-dark" />
                                <span class="text-muted">{{vehicle.registration?.length || 0}}/11 Characters (Minimum 3, Maximum 11)</span>
        
                            </div>

                            <table class="table">
                                <thead class="bg-primary text-white">
                                    <tr>
                                        <th>Attempted Registration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="reg in vehicle.attempted">
                                        <td>{{reg}}</td>
                                    </tr>
                                </tbody>
                            </table>
    
                        </div>
    
                        <div class="form-group">
    
                            <p>In order to continue, you must explain why you are happy with the information.</p>

                            <p>If there is no image or the image isn't clear, consider asking the site for a clearer image before their CCTV expires!</p>
    
                            <label class="form-control-label">Justification <span class="text-danger">*</span></label>
                            <textarea onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="vehicle.justification" class="form-control form-control-dark" rows="3"></textarea>
                            <span class="text-muted">{{vehicle.justification?.length || 0}}/10 Characters</span>
    
                        </div>
    
                        <p class="text-warning" v-if="vehicle.hadMismatch">The values you have entered do not match the ones provided by the site. Please try again or set the claim to tampered plate.</p>

                        <button v-if="vehicle.hadMismatch" class="btn btn-sm btn-warning" @click="tamperedPlate">Tampered Plate</button>

                        <!-- <label v-if="vehicle.hadMismatch" class="ckbox">
                            <input v-model="vehicle.acceptance" type="checkbox">
                            <span>I am aware they do not match and the values I have entered are correct</span>
                        </label> -->
    
                        <button @click="verifyVehicle()" class="btn btn-sm btn-primary">Verify</button>
    
                    </div>
                </div>

            </div>

            <!-- Step 3: Check the make, model and colour match the vehicle in the image -->
            <div v-if="step == 3">
                <p><strong>Check that the make, model and colour match the vehicle in the image</strong></p>
                
                <div class="row">
                    <div class="col-6">

                        <secure-image v-viewer v-if="vehicle_image !== null && vehicle_file_type !== 'pdf'" :filename="vehicle_image.filename" :site_id="claim.site.id" :endpoint="'/api/claims/' + claim.id + '/evidence/'" :client_id="client_id" :storage_account="vehicle_image.storage_account" style="max-height: 75vh; width: auto; max-width: 100%; height: auto;" height="auto"
                        :class="{'rotate-90': currentRotation == 90, 'rotate-180': currentRotation == 180, 'rotate-270': currentRotation == 270}">

                            <p> <i class="fa-regular fa-triangle-exclamation text-danger"></i> There was an issue loading the image.</p>

                        </secure-image>

                        <div v-if="vehicle_image !== null">
                            <button class="btn btn-primary btn-sm" @click="rotate">Rotate</button>
                        </div>

                        <div v-if="vehicle_image !== null && vehicle_file_type == 'pdf'" style="max-height: 75vh; width: auto; max-width: 100%;">
                            <iframe :src="vehicle_pdf_url" width="100%" style="height: 75vh;"></iframe>
                        </div>
    
                        <div v-else class="alert alert-danger" role="alert">
                            <div class="d-flex align-items-center justify-content-start">
                              <i class="icon ion-ios-checkmark alert-icon tx-32 mg-t-5 mg-xs-t-0"></i>
                              <span><strong>Problem!</strong> The system is unable to detect a valid event image attached to this claim. </span>
                            </div><!-- d-flex -->
                        </div><!-- alert -->
    
                    </div>
                    <div class="col-6">

                        <p>Try to use your best judgement when deciding if the make, model and colour match. If you aren't sure what the vehicle should look like we have provided some reference images below.</p>

                        <p><strong>Expected Make:</strong> {{ claim.make }}</p>
                        <p><strong>Expected Model:</strong> {{ claim.model }}</p>
                        <p><strong>Expected Colour:</strong> {{ claim.colour }}</p>

                        <p>Adjust the year to update the example images.</p>

                        <div class="form-group">
    
                            <label class="form-control-label">Year</label>
                            <select class="form-control form-control-dark" @change="updateSearchTerm" v-model="year">
                                <option value="2030">2030</option>
                                <option value="2029">2029</option>
                                <option value="2028">2028</option>
                                <option value="2027">2027</option>
                                <option value="2026">2026</option>
                                <option value="2025">2025</option>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                                <option value="2019">2019</option>
                                <option value="2018">2018</option>
                                <option value="2017">2017</option>
                                <option value="2016">2016</option>
                                <option value="2015">2015</option>
                                <option value="2014">2014</option>
                                <option value="2013">2013</option>
                                <option value="2012">2012</option>
                                <option value="2011">2011</option>
                                <option value="2010">2010</option>
                                <option value="2009">2009</option>
                                <option value="2008">2008</option>
                                <option value="2007">2007</option>
                                <option value="2006">2006</option>
                                <option value="2005">2005</option>
                                <option value="2004">2004</option>
                                <option value="2003">2003</option>
                                <option value="2002">2002</option>
                                <option value="2001">2001</option>
                                <option value="2000">2000</option>
                                <option value="1999">1999</option>
                                <option value="1998">1998</option>
                                <option value="1997">1997</option>
                                <option value="1996">1996</option>
                                <option value="1995">1995</option>
                                <option value="1994">1994</option>
                                <option value="1993">1993</option>
                                <option value="1992">1992</option>
                                <option value="1991">1991</option>
                                <option value="1990">1990</option>
                                <option value="1989">1989</option>
                                <option value="1988">1988</option>
                                <option value="1987">1987</option>
                                <option value="1986">1986</option>
                                <option value="1985">1985</option>
                                <option value="1984">1984</option>
                                <option value="1983">1983</option>
                                <option value="1982">1982</option>
                                <option value="1981">1981</option>
                                <option value="1980">1980</option>
                                <option value="1979">1979</option>
                                <option value="1978">1978</option>
                                <option value="1977">1977</option>
                                <option value="1976">1976</option>
                                <option value="1975">1975</option>
                                <option value="1974">1974</option>
                                <option value="1973">1973</option>
                                <option value="1972">1972</option>
                                <option value="1971">1971</option>
                                <option value="1970">1970</option>
                                <option value="1969">1969</option>
                                <option value="1968">1968</option>
                                <option value="1967">1967</option>
                                <option value="1966">1966</option>
                                <option value="1965">1965</option>
                                <option value="1964">1964</option>
                                <option value="1963">1963</option>
                                <option value="1962">1962</option>
                                <option value="1961">1961</option>
                                <option value="1960">1960</option>
                                <option value="1959">1959</option>
                                <option value="1958">1958</option>
                                <option value="1957">1957</option>
                                <option value="1956">1956</option>
                                <option value="1955">1955</option>
                                <option value="1954">1954</option>
                                <option value="1953">1953</option>
                                <option value="1952">1952</option>
                                <option value="1951">1951</option>
                                <option value="1950">1950</option>
                                <option value="1949">1949</option>
                                <option value="1948">1948</option>
                                <option value="1947">1947</option>
                                <option value="1946">1946</option>
                                <option value="1945">1945</option>
                                <option value="1944">1944</option>
                                <option value="1943">1943</option>
                                <option value="1942">1942</option>
                                <option value="1941">1941</option>
                                <option value="1940">1940</option>
                                <option value="1939">1939</option>
                                <option value="1938">1938</option>
                                <option value="1937">1937</option>
                                <option value="1936">1936</option>
                                <option value="1935">1935</option>
                                <option value="1934">1934</option>
                                <option value="1933">1933</option>
                                <option value="1932">1932</option>
                                <option value="1931">1931</option>
                                <option value="1930">1930</option>
                                <option value="1929">1929</option>
                                <option value="1928">1928</option>
                                <option value="1927">1927</option>
                                <option value="1926">1926</option>
                                <option value="1925">1925</option>
                                <option value="1924">1924</option>
                                <option value="1923">1923</option>
                                <option value="1922">1922</option>
                                <option value="1921">1921</option>
                                <option value="1920">1920</option>
                                <option value="1919">1919</option>
                                <option value="1918">1918</option>
                                <option value="1917">1917</option>
                                <option value="1916">1916</option>
                                <option value="1915">1915</option>
                                <option value="1914">1914</option>
                                <option value="1913">1913</option>
                                <option value="1912">1912</option>
                                <option value="1911">1911</option>
                                <option value="1910">1910</option>
                                <option value="1909">1909</option>
                                <option value="1908">1908</option>
                                <option value="1907">1907</option>
                                <option value="1906">1906</option>
                                <option value="1905">1905</option>
                                <option value="1904">1904</option>
                                <option value="1903">1903</option>
                                <option value="1902">1902</option>
                                <option value="1901">1901</option>
                                <option value="1900">1900</option>
                            </select>
    
                        </div>

                        <div id="carousel1" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner" role="listbox">
                              <div v-for="(example, index) in exampleImages" :key="'img-' + example" class="carousel-item" :class="{'active': index == 0}">
                                <img style="width: 100%; height: auto;" :src="example">
                              </div>
                            </div><!-- carousel-inner -->
                            <a class="carousel-control-prev" href="#carousel1" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carousel1" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                        </div><!-- carousel -->

                        <div class="form-group">
    
                            <label class="form-control-label">Are you satisfied the make, model and colour match? <span class="text-danger">*</span></label>
                            <select class="form-control form-control-dark" v-model="vehicle_two.mmc_match">
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
    
                        </div>
                        
                        <div v-if="vehicle_two.mmc_match == 'No'">

                            <p>Please enter the correct make, model and colour of the vehicle. Do not guess the spelling, if you are unsure google the correct spelling.</p>

                            <div class="form-group">

                                <label class="form-control-label">Make <span class="text-danger">*</span></label>
                                <input onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="vehicle_two.make" type="text" class="form-control form-control-dark" placeholder="Make">

                            </div>

                            <div class="form-group">

                                <label class="form-control-label">Model <span class="text-danger">*</span></label>
                                <input onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="vehicle_two.model" type="text" class="form-control form-control-dark" placeholder="Model">

                            </div>

                            <div class="form-group">

                                <label class="form-control-label">Colour <span class="text-danger">*</span></label>
                                <input onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="vehicle_two.colour" type="text" class="form-control form-control-dark" placeholder="Colour">

                            </div>

                            <p class="text-danger">Abuse of this facility will be monitored</p>
                            <button class="btn btn-sm btn-primary" @click="updateExamples">Update Examples</button>
                            <br/>

                        </div>

                        <div class="form-group">
    
                            <p>In order to continue, you must explain why you are happy with the information.</p>
    
                            <label class="form-control-label">Justification <span class="text-danger">*</span></label>
                            <textarea onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="vehicle_two.justification" class="form-control form-control-dark" rows="3"></textarea>
                            <span class="text-muted">{{vehicle_two.justification?.length || 0}}/10 Characters</span>
    
                        </div>
    
                        <button @click="verifyVehicleTwo()" class="btn btn-sm btn-primary">Verify</button>

                    </div>
                </div>

            </div>

            <!-- Step 4: Check the fuel type matches that of the vehicle -->
            <div v-if="step == 4">
                <p><strong>Check that the fuel type matches that of the vehicle</strong></p>
                
                <p><strong>Expected Fuel Type:</strong> {{ claim.override_mmc == 1 ? claim.vehicle_fueltype : claim.fuelType }}</p>
                <p><strong>Provided Fuel Type:</strong> {{ receipt.fuel_type }} </p>
                <p v-if="receipt.fuel_second !== null"><strong>Provided 2nd Fuel Type:</strong> {{ receipt.fuel_second }}</p>

                <p>The system has detected that the provided fuel type does not match the vehicle fuel type. Before we can continue we need to make sure we can justify this mismatch.</p>

                <p>If there is a site comment, note or image that justifies the fuel type not matching such as an image of the person filling a cannister then please explain this in your justification. Otherwise, please get in contact with the site to determine what has happened.</p>

                <p><strong>Site Telephone:</strong> {{ claim.site.telephone || "No number on record." }}</p>

                <div class="form-group">

                    <label class="form-control-label">Were you able to get in contact with the site? <span class="text-danger">*</span></label>
                    <select class="form-control form-control-dark" v-model="fuelType.contacted_site">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>

                </div>

                <div v-if="fuelType.contacted_site == 'No'">

                    <p>Please lock the claim as set a reminder to follow this up.</p>

                </div>

                <div v-if="fuelType.contacted_site == 'Yes'" class="form-group">

                    <label class="form-control-label">Did the site confirm the fuel type? <span class="text-danger">*</span></label>
                    <select class="form-control form-control-dark" v-model="fuelType.site_confirmed">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>

                </div>

                <div v-if="fuelType.site_confirmed == 'Yes'" class="form-group">

                    <label class="form-control-label">What did the site say? <span class="text-danger">*</span></label>
                    <input onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="fuelType.site_response" type="text" class="form-control form-control-dark" placeholder="Site Response">

                </div>

                <div v-if="fuelType.site_confirmed == 'Yes'" class="form-group">

                    <label class="form-control-label">Was a cannister used for {{ receipt.fuel_type }}? <span class="text-danger">*</span></label>
                    <select class="form-control form-control-dark" v-model="receipt.is_cannister">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                    </select>

                </div>

                <div v-if="fuelType.site_confirmed == 'Yes' && receipt.fuel_second" class="form-group">

                    <label class="form-control-label">Was a cannister used for {{ receipt.fuel_second }}? <span class="text-danger">*</span></label>
                    <select class="form-control form-control-dark" v-model="receipt.is_cannister_second">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                    </select>

                </div>

                <div v-if="fuelType.site_confirmed == 'No'" class="form-group">

                    <p>If we cannot justify the fuel type mismatch we cannot continue to process the claim.</p>

                    <button class="btn btn-sm btn-danger" @click="cancelClaim">Cancel Claim</button>

                </div>

                <div v-if="fuelType.site_confirmed !== 'No'" class="form-group">
    
                    <p>In order to continue, you must explain why you are happy with the information.</p>

                    <label class="form-control-label">Justification <span class="text-danger">*</span></label>
                    <textarea onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="fuelType.justification" class="form-control form-control-dark" rows="3"></textarea>
                    <span class="text-muted">{{fuelType.justification?.length || 0}}/10 Characters</span>

                </div>

                <button v-if="fuelType.site_confirmed !== 'No' && fuelType.contacted_site !== 'No'" @click="verifyFuelType()" class="btn btn-sm btn-primary">Verify</button>

            </div>

            <!-- Step 5 (NEW): Check the date and time matches the evidence provided -->
            <div v-if="step == 5">

                <p><strong>Verify the date and time of the incident</strong></p>

                <div class="row">

                    <div class="col-6">

                        <div class="row">
                            <div class="col">
                                <SecureImage v-viewer v-if="till_receipt !== null && till_file_type !== 'pdf'" :filename="till_receipt.filename" :client_id="client_id" :claim_id="claim.id" :type="'till_receipt'" :site_id="claim.site_id" :storage_account="till_receipt.storage_account" style="max-height: 75vh; width: auto; max-width: 100%;" :endpoint="'/api/claims/' + claim.id + '/evidence/'" :class="{'rotate-90': currentRotation == 90, 'rotate-180': currentRotation == 180, 'rotate-270': currentRotation == 270}" />

                                <div v-if="till_receipt !== null">
                                    <button class="btn btn-primary btn-sm" @click="rotate">Rotate</button>
                                </div>

                                <div v-if="till_receipt !== null && till_file_type == 'pdf'" style="max-height: 75vh; width: auto; max-width: 100%;">
                                    <iframe :src="pdf_url" width="100%" style="height: 75vh;"></iframe>
                                </div>

                                <div v-if="till_receipt == null" class="alert alert-danger" role="alert">
                                    <div class="d-flex align-items-center justify-content-start">
                                    <i class="icon ion-ios-checkmark alert-icon tx-32 mg-t-5 mg-xs-t-0"></i>
                                    <span><strong>Problem!</strong> The system is unable to detect a valid till receipt attached to this claim. </span>
                                    </div><!-- d-flex -->
                                </div><!-- alert -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <secure-image v-viewer v-if="vehicle_image !== null && vehicle_file_type !== 'pdf'" :filename="vehicle_image.filename" :site_id="claim.site.id" :endpoint="'/api/claims/' + claim.id + '/evidence/'" :client_id="client_id" :storage_account="vehicle_image.storage_account" style="max-height: 75vh; width: auto; max-width: 100%; height: auto;" height="auto"
                        :class="{'rotate-90': currentRotation == 90, 'rotate-180': currentRotation == 180, 'rotate-270': currentRotation == 270}">

                            <p> <i class="fa-regular fa-triangle-exclamation text-danger"></i> There was an issue loading the image.</p>

                        </secure-image>

                        <div v-if="vehicle_image !== null">
                            <button class="btn btn-primary btn-sm" @click="rotate">Rotate</button>
                        </div>

                        <div v-if="vehicle_image !== null && vehicle_file_type == 'pdf'" style="max-height: 75vh; width: auto; max-width: 100%;">
                            <iframe :src="vehicle_pdf_url" width="100%" style="height: 75vh;"></iframe>
                        </div>
            
                                <div v-else class="alert alert-danger" role="alert">
                                    <div class="d-flex align-items-center justify-content-start">
                                    <i class="icon ion-ios-checkmark alert-icon tx-32 mg-t-5 mg-xs-t-0"></i>
                                    <span><strong>Problem!</strong> The system is unable to detect a valid event image attached to this claim. </span>
                                    </div><!-- d-flex -->
                                </div><!-- alert -->
                            </div>
                        </div>

                    </div>

                    <div class="col-6">
                        <div class="form-group">
                            <label class="form-control-label">Does the receipt show the nozzle back date/time? <span class="text-danger">*</span></label>
                            <select class="form-control form-control-dark" v-model="receipt.nozzle_back">
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>

                        <div class="form-group" v-show="receipt.nozzle_back == 'Yes'">
                            <label class="form-control-label">Does the nozzle back time match the expected date/time? <span class="text-danger">*</span></label>
                            <select class="form-control form-control-dark" v-model="receipt.nozzle_back_matches">
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>

                        <div class="form-group" v-show="receipt.nozzle_back == 'No'">
                            <label class="form-control-label">Is the receipt printed after the expected date/time? <span class="text-danger">*</span></label>
                            <select class="form-control form-control-dark" v-model="receipt.receipt_after_expected">
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>

                        <div class="form-group" v-show="receipt.receipt_after_expected == 'No'">
                            <label class="form-control-label">Have you contacted the forecourt? <span class="text-danger">*</span></label>
                            <select class="form-control form-control-dark" v-model="receipt.receipt_after_expected_contact">
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>

                        <div class="form-group" v-show="receipt.receipt_after_expected_contact == 'No'">
                            <p class="text-warning">Please contact the forecourt and ask why the date/time on the receipt is before the incident date/time!</p>
                        </div>

                        <div class="form-group" v-show="receipt.receipt_after_expected_contact == 'Yes'">
                            <label class="form-control-label">What did the forecourt say? <span class="text-danger">*</span></label>
                            <textarea class="form-control form-control-dark" v-model="receipt.receipt_after_expected_explanation"></textarea>
                        </div>

                        <div class="form-group" v-show="receipt.nozzle_back == 'Yes' || receipt.receipt_after_expected == 'Yes' || receipt.receipt_after_expected_explanation?.length > 10">
    
                            <p>In order to continue, you must explain why you are happy with the information.</p>
    
                            <label class="form-control-label">Justification <span class="text-danger">*</span></label>
                            <textarea onpaste="return false;" ondrop="return false;" autocomplete="off" v-model="receipt.datetime_justification" class="form-control form-control-dark" rows="3"></textarea>
                            <span class="text-muted">{{receipt.datetime_justification?.length || 0}}/10 Characters</span>
    
                        </div>

                        <button v-show="receipt.nozzle_back == 'Yes' || receipt.receipt_after_expected == 'Yes' || receipt.receipt_after_expected_explanation?.length > 10" @click="verifyDateTime()" class="btn btn-sm btn-primary">Verify</button>

                    </div>

                </div>

            </div>

            <!-- Step 6: Generate the DVLA enquiry form -->
            <div v-if="step == 6">
                <p><strong>Generate the DVLA enquiry form</strong></p>
                
                <p class="text-warning">Please read through your responses below and confirm you are happy with them. Once you generate the enquiry form, any adjustments you have made and justifications you have provided will be saved.</p>

                <p style="text-decoration: underline;"><strong>Till Receipt</strong></p>

                <p v-if="receipt.hadMismatch" class="text-warning">
                    <i class="fas fa-exclamation-triangle"></i> The information you entered did not match the information stored. You have accepted the information you entered as correct. 
                </p>

                <p>
                    <strong>Fuel Type:</strong> {{ receipt.fuel_type }} <br>
                    <strong>Litres:</strong> {{ receipt.litres }} <br>
                    <strong>Value:</strong> £{{ receipt.value }} <br>
                    <strong>Pump:</strong> {{ receipt.pump }} <br>
                    <strong>Cannister?:</strong> {{ receipt.is_cannister ? 'Yes' : 'No'}}
                    <span v-if="receipt.fuel_second !== null">
                        <strong>2nd Fuel Type:</strong> {{ receipt.fuel_second }} <br>
                        <strong>2nd Fuel Litres:</strong> {{ receipt.litres_second }} <br>
                        <strong>2nd Fuel Value:</strong> £{{ receipt.value_second }} <br>
                        <strong>2nd Fuel Pump:</strong> {{ receipt.pump_second }} <br>
                        <strong>2nd Fuel Cannister?:</strong> {{ receipt.is_cannister_second ? 'Yes' : 'No'}}
                    </span>
                    <strong>Justification:</strong> {{ receipt.justification }}
                </p>

                <p style="text-decoration: underline;"><strong>Vehicle Image</strong></p>

                <p v-if="vehicle.hadMismatch" class="text-warning">
                    <i class="fas fa-exclamation-triangle"></i> The information you entered did not match the information stored. You have accepted the information you entered as correct.
                </p>

                <p>
                    <strong>Is there a vehicle in the image?</strong> {{ vehicle.vehicle_in_image }} <br>
                    <span v-if="vehicle.vehicle_in_image == 'Yes'">
                        <strong>Can you make out the registration?</strong> {{ vehicle.registration_in_image }} <br>
                    </span> 
                    <span v-if="vehicle.registration_in_image == 'Yes'">
                        <strong>Registration:</strong> {{ vehicle.registration }} <br>
                    </span>
                    <span v-if="vehicle.vehicle_in_image == 'No'">
                        <strong>I have contacted the site and they have confirmed that they are unable to provide a vehicle image</strong> {{ vehicle.no_image_available ? 'Yes' : 'No' }} <br/>
                    </span>

                    <strong>Justification:</strong> {{ vehicle.justification }}

                </p>

                <p v-if="this.vehicle.vehicle_in_image == 'Yes'" style="text-decoration: underline;"><strong>Vehicle Image Part 2</strong></p>
                
                <p v-if="vehicle_two.mmc_match == 'No' && this.vehicle.vehicle_in_image == 'Yes'" class="text-warning">
                    <i class="fas fa-exclamation-triangle"></i> The information you entered did not match the information stored. You have accepted the information you entered as correct.
                </p>

                <p v-if="this.vehicle.vehicle_in_image == 'Yes'">
                    <strong>Are you satisfied the make, model and colour match the vehicle?</strong> {{ vehicle_two.mmc_match }} <br>
                    <span v-if="vehicle_two.mmc_match == 'No'">
                        <strong>Provided Make:</strong> {{ vehicle_two.make }} <br>
                        <strong>Provided Model:</strong> {{ vehicle_two.model }} <br>
                        <strong>Provided Colour:</strong> {{ vehicle_two.colour }} <br>
                    </span>
                    <strong>Justification:</strong> {{ vehicle_two.justification }}
                </p>

                <p v-if="this.receipt.fuel_type != this.claimFuelType" style="text-decoration: underline;"><strong>Fuel Type</strong></p>

                <p v-if="fuelType.fuel_type_match == 'No' && this.receipt.fuel_type != this.claimFuelType" class="text-warning">
                    <i class="fas fa-exclamation-triangle"></i> The information you entered did not match the information stored. You have accepted the information you entered as correct. 
                </p>

                <p v-if="this.receipt.fuel_type != this.claimFuelType">
                    <strong>Were you able to get in contact with the site?</strong> {{ fuelType.contacted_site }} <br>
                    <span v-if="fuelType.contacted_site == 'Yes'">
                        <strong>Did the site confirm the fuel type?</strong> {{ fuelType.site_confirmed }} <br>
                        <span v-if="fuelType.site_confirmed == 'Yes'">
                            <strong>Site Response:</strong> {{ fuelType.site_response }} <br>
                        </span>
                    </span>
                    <strong>Justification:</strong> {{ fuelType.justification }}
                </p>

                <p v-if="this.receipt.datetime_justification == 'Yes'" style="text-decoration: underline;"><strong>Date/Time</strong></p>

                <strong>Does the receipt show the nozzle back date/time?</strong> {{ receipt.nozzle_back }} <br>
                <span v-if="receipt.nozzle_back == 'Yes'">
                    <strong>Does the nozzle back time match the expected date/time?</strong> {{ receipt.nozzle_back_matches }} <br>
                </span>
                <span v-else>
                    <strong>Is the receipt printed after the expected date/time?</strong> {{ receipt.receipt_after_expected }} <br>
                    <span v-if="receipt.receipt_after_expected == 'No'">
                        <strong>Have you contacted the forecourt?</strong> {{ receipt.receipt_after_expected_contact }} <br>
                        <span v-if="receipt.receipt_after_expected_contact == 'Yes'">
                            <strong>What did the forecourt say?</strong> {{ receipt.receipt_after_expected_explanation }} <br>
                        </span>
                    </span>
                </span>

                <strong>Justification:</strong> {{ receipt.datetime_justification }} <br>

                <div v-if="!in_future">
                    <button v-if="!saved" :class="{'disabled': saving || downloading}" @click="generateDVLAEnquiry()" class="btn btn-sm btn-primary mg-r-10">Generate DVLA Enquiry</button>
                    <button v-if="saved" @click="downloadEnquiry()" class="btn btn-sm btn-primary mg-r-10">Generate DVLA Enquiry</button>
                    <button v-if="downloaded && saved" @click="step = 7" class="btn btn-sm btn-primary mg-r-10">Continue</button>
                    <button @click="step = 1" class="btn btn-sm btn-warning">Check Again</button>
                </div>
                <div v-else>
                    <p class="text-warning">The date/time you have entered is in the future. Please check the date/time and try again.</p>
                    <button @click="step = 1" class="btn btn-sm btn-warning">Check Again</button>
                </div>

                <p v-if="saving || downloading">Saving the changes, please wait...</p>

            </div>

            <!-- Step 7: Mark claim at the DVLA -->
            <div v-if="step == 7">
                <p><strong>Mark claim At DVLA</strong></p>
                
                <button @click="atDvla" class="btn btn-sm btn-primary mg-r-10">Sent to DVLA</button>
            </div>
            
        </div>   
    </div>
</template>

<script>
import SecureImage from '@/components/SecureImage.vue';
import {directive as viewer} from 'v-viewer';
import axios from 'axios';
import {DateTime} from 'luxon';
import authHeader from '@/services/auth-header';

export default {
    name: 'DriveoffPending',
    directives: {
        viewer: viewer({
            debug: false
        })
    },
    props: {
        claim: {
            type: Object,
            required: true
        },
        client_id: {
            type: [Number, String],
            required: false
        }
    },
    components: {
        SecureImage
    },
    data(){
        return {
            step: 1,
            searchTerm: '',
            year: null,
            exampleImages: [],
            currentRotation: 0,
            receipt: {
                fuel_type: null,
                litres: null,
                value: null,
                pump: null,
                fuel_second: null,
                litres_second: null,
                value_second: null,
                pump_second: null,
                date: null,
                time: null,
                justification: null,
                hadMismatch: false,
                acceptance: false,
                checkedDateTime: null,
                is_cannister: null,
                is_cannister_second: null,
                nozzle_back: null,
                nozzle_back_matches: null,
                receipt_after_expected: null,
                receipt_after_expected_contact: null,
                receipt_after_expected_explanation: null,
                datetime_justification: null
            },
            vehicle: {
                registration: null,
                vehicle_in_image: null,
                registration_visible: null,
                justification: null,
                hadMismatch: false,
                acceptance: false,
                attempted: [],
                no_image_available: null
            },
            vehicle_two: {
                justification: null,
                mmc_match: null,
                make: null,
                model: null,
                colour: null
            },
            fuelType: {
                justification: null,
                site_confirmed: null,
                site_response: null,
                contacted_site: null
            },
            saving: false,
            downloading: false,
            saved: false,
            downloaded: false,
            pdf_url: "",
            vehicle_pdf_url: "",
        }
    },
    mounted(){
        this.searchTerm = `${this.claim.make || ''} ${this.claim.model || ''} ${this.claim.colour || ''}`;
        if(this.claim.override_mmc == 1){
            this.searchTerm = `${this.claim.vehicle_make || ''} ${this.claim.vehicle_model || ''} ${this.claim.vehicle_colour || ''}`;
        }
        setTimeout(() => {
            if(this.till_file_type == 'pdf'){
                if(this.pdf_url){
                    URL.revokeObjectURL(this.pdf_url);
                }
                axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${this.till_receipt.filename}?site_id=${this.claim.site_id}&storage_account=${this.till_receipt.storage_account || 1}&client_id=${this.client_id}`, {
                    responseType: 'arraybuffer',
                    headers: authHeader()
                })
                .then(response => {
                    let blob = new Blob([response.data], {type: 'application/pdf'});
                    this.pdf_url = URL.createObjectURL(blob);
                })
                .catch(error => {
                    console.log(error);
                    this.pdf_url = "";
                });
            }

            if(this.vehicle_file_type == 'pdf'){
                if(this.vehicle_pdf_url){
                    URL.revokeObjectURL(this.vehicle_pdf_url);
                }
                axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${this.vehicle_image.filename}?site_id=${this.claim.site_id}&storage_account=${this.vehicle_image.storage_account || 1}&client_id=${this.client_id}`, {
                    responseType: 'arraybuffer',
                    headers: authHeader()
                })
                .then(response => {
                    let blob = new Blob([response.data], {type: 'application/pdf'});
                    console.log(`Setting vehicle_pdf_url`);
                    this.vehicle_pdf_url = URL.createObjectURL(blob);
                    console.log(`vehicle_pdf_url set to ${this.vehicle_pdf_url}`);
                })
                .catch(error => {
                    console.log(error);
                    this.vehicle_pdf_url = "";
                });
            }
        }, 1500);
    },
    beforeUnmount(){
        if(this.pdf_url){
            URL.revokeObjectURL(this.pdf_url);
        }
        if(this.vehicle_pdf_url){
            URL.revokeObjectURL(this.vehicle_pdf_url);
        }
    },
    methods: {
        rotate(){
            if(this.currentRotation == 0){
                this.currentRotation = 90;
            }
            else if(this.currentRotation == 90){
                this.currentRotation = 180;
            }
            else if(this.currentRotation == 180){
                this.currentRotation = 270;
            }
            else if(this.currentRotation == 270){
                this.currentRotation = 0;
            }
        },
        verifyReceipt(){

            this.$emit('pendingLock', false);

            let expectedFuelType = this.claim.fuel;
            let expectedLitres = this.claim.litres;
            let expectedValue = this.claim.value;
            let expectedPump = this.claim.pump;

            let secondExpectedFuelType = this.claim.fuel_second;
            let secondExpectedLitres = this.claim.litres_second;
            let secondExpectedValue = this.claim.value_second;
            let secondExpectedPump = this.claim.pump_second;

            let matches = true;
            if(this.receipt.fuel_type != expectedFuelType){
                if(this.receipt.fuel_type == 'AdBlue' && expectedFuelType == 'Ad Blue'){
                    console.log('AdBlue matches Ad Blue');
                }else{
                    console.log(this.receipt.fuel_type, ' does not equal ', expectedFuelType);
                    matches = false;
                }
            }

            if(parseFloat(this.receipt.litres) != parseFloat(expectedLitres)){
                console.log(this.receipt.litres, ' does not equal ', expectedLitres);
                matches = false;
            }

            if(parseFloat(this.receipt.value) != parseFloat(expectedValue)){
                console.log(this.receipt.value, ' does not equal ', expectedValue)
                matches = false;
            }

            if(parseInt(this.receipt.pump) != parseInt(expectedPump)){
                console.log(this.receipt.pump, ' does not equal ', expectedPump)
                matches = false;
            }

            if(this.receipt.fuel_second != -1){

                if(this.receipt.fuel_second != secondExpectedFuelType){
                    console.log(this.receipt.fuel_second, ' does not equal ', secondExpectedFuelType)
                    matches = false;
                }

                if(parseFloat(this.receipt.litres_second) != parseFloat(secondExpectedLitres)){
                    console.log(this.receipt.litres_second, ' does not equal ', secondExpectedLitres)
                    matches = false;
                }

                if(parseFloat(this.receipt.value_second) != parseFloat(secondExpectedValue)){
                    console.log(this.receipt.value_second, ' does not equal ', secondExpectedValue)
                    matches = false;
                }

                if(parseInt(this.receipt.pump_second) != parseInt(secondExpectedPump)){
                    console.log(this.receipt.pump_second, ' does not equal ', secondExpectedPump)
                    matches = false;
                }

            }

            this.receipt.hadMismatch = !matches;

            if(this.receipt.justification == null || this.receipt.justification.length < 10){
                alert("Please provide a justification to continue! Must be at least 10 characters long.");
                return;
            }

            if(matches || this.receipt.acceptance){
                if(this.receipt.fuel_second == -1){
                    this.receipt.fuel_second = null;
                }
                this.step = 2;
                this.$emit('pendingLock', false);
            }

        },
        verifyVehicle(){

            let expectedRegistration = this.claim.vehicle.plate;

            if((this.vehicle.registration == '' || this.vehicle.registration == null) && this.vehicle.registration_visible == 'Yes') return;

            let matches = true;
            if(this.vehicle.registration != expectedRegistration && this.vehicle.registration_visible == 'Yes'){
                console.log(this.vehicle.registration, ' does not equal ', expectedRegistration);
                this.vehicle.attempted.push(this.vehicle.registration);
                this.vehicle.registration = null;
                axios.put(`https://api.varsanpr.com/api/claims/${this.claim.id}/tamperedplate`, {
                    registration: this.vehicle.attempted,
                    client_id: this.client_id
                }, {
                    headers: authHeader()
                }).then(res => {
                    console.log(res);
                }).catch(err => {
                    console.log(err);
                });
                matches = false;
            }

            if(this.vehicle.vehicle_in_image == null){
                console.log('Vehicle in image is null');
                alert('You must select if the vehicle is in the image or not.')
                return;
            }

            if(this.vehicle.vehicle_in_image == 'Yes' && this.vehicle.registration_visible == null){
                console.log('Vehicle in image is yes but registration visible is null');
                alert('You must select if the registration is visible or not if the vehicle is in the image.')
                return;
            }

            if(this.vehicle.vehicle_in_image == 'No' && this.vehicle.no_image_available !== true){
                console.log('Vehicle in image is no but no image available is not true');
                alert('You must confirm that no vehicle image is available when the vehicle is not in the image.')
                return;
            }

            this.vehicle.hadMismatch = !matches;

            if(this.vehicle.justification == null || this.vehicle.justification.length < 10){
                alert("Please provide a justification to continue! Must be at least 10 characters long.");
                return;
            }

            if(matches){
                this.$emit('pendingLock', false);
                if(this.vehicle.vehicle_in_image == 'Yes'){
                    this.loadExampleImages();
                    this.$emit('pendingLock', true);  
                    this.step = 3;
                }else{

                    let allowedExceptions = [
                        {
                            expected: 'Diesel',
                            provided: 'AdBlue'
                        },
                        {
                            expected: 'Electric Diesel',
                            provided: 'Diesel'
                        },
                        {
                            expected: 'Hybrid Electric (Clean)',
                            provided: 'Petrol'
                        }
                    ];

                    let allowed = false;

                    if(this.receipt.fuel_type == this.claimFuelType){
                        allowed = true;
                    }

                    for(let i = 0; i < allowedExceptions.length; i++){
                        if(allowedExceptions[i].expected == this.claimFuelType && allowedExceptions[i].provided == this.receipt.fuel_type){
                            allowed = true;
                        }
                    }

                    let secondAllowed = false;

                    if(this.receipt.fuel_second === null){
                        secondAllowed = true;
                    }else{
                        for(let i = 0; i < allowedExceptions.length; i++){
                            console.log(`Expected: ${allowedExceptions[i].expected} Provided: ${allowedExceptions[i].provided} Second: ${this.receipt.fuel_second}`);
                            if(allowedExceptions[i].expected == this.claimFuelType && allowedExceptions[i].provided == this.receipt.fuel_second){
                                secondAllowed = true;
                            }
                        }
                    }

                    if(allowed && secondAllowed){
                        this.$emit('pendingLock', false);
                        this.step = 5;
                    }else{
                        this.$emit('pendingLock', true);
                        this.step = 4;
                    }
                }
            }

        },
        verifyVehicleTwo(){

            if(this.vehicle_two.mmc_match == null){
                alert('You must select if the vehicle matches the MMC or not.');
                return;
            }

            if(this.vehicle_two.mmc_match == 'No'){
                if(this.vehicle_two.make == null || this.vehicle_two.model == null || this.vehicle_two.colour == null){
                    alert('You must enter a make, model and colour if the vehicle does not match the MMC.');
                    return;
                }
            }

            if(this.vehicle_two.justification == null || this.vehicle_two.justification.length < 10){
                alert("Please provide a justification to continue! Must be at least 10 characters long.");
                return;
            }

            if(this.receipt.fuel_type == this.claimFuelType && this.receipt.fuel_second == null){
                this.$emit('pendingLock', false);
                this.step = 5; // Skip to step 5
            }else{

                // Allow certain exceptions:
                // Diesel / AdBlue
                // Electric Diesel / Diesel
                // Hybrid Electric (Clean) / Petrol

                let allowedExceptions = [
                    {
                        expected: 'Diesel',
                        provided: 'AdBlue'
                    },
                    {
                        expected: 'Electric Diesel',
                        provided: 'Diesel'
                    },
                    {
                        expected: 'Hybrid Electric (Clean)',
                        provided: 'Petrol'
                    },
                    {
                        expected: 'AdBlue',
                        provided: 'Diesel'
                    }
                ];

                let allowed = false;

                for(let i = 0; i < allowedExceptions.length; i++){
                    if(allowedExceptions[i].expected == this.claimFuelType && allowedExceptions[i].provided == this.receipt.fuel_type){
                        allowed = true;
                    }
                }

                let secondAllowed = false;

                console.log(`Expected Fuel: ${this.claimFuelType} Second Fuel: ${this.receipt.fuel_second}`);

                if(this.receipt.fuel_second === null){
                    secondAllowed = true;
                }else{
                    for(let i = 0; i < allowedExceptions.length; i++){
                        console.log(`Expected: ${allowedExceptions[i].expected} Provided: ${allowedExceptions[i].provided} Second: ${this.receipt.fuel_second}`);
                        if(allowedExceptions[i].expected == this.claimFuelType && allowedExceptions[i].provided == this.receipt.fuel_second){
                            secondAllowed = true;
                        }
                    }
                }

                if(allowed && secondAllowed){
                    this.$emit('pendingLock', false);
                    this.step = 5;
                    return;
                }

                this.$emit('pendingLock', true);
                this.step = 4;
            }

        },
        verifyFuelType(){
            if(this.fuelType.justification == null || this.fuelType.justification.length < 10){
                alert("Please provide a justification to continue! Must be at least 10 characters long.");
                return;
            }

            this.step = 5;
            this.$emit('pendingLock', false);
        },
        verifyDateTime(){
            if(this.receipt.datetime_justification == null || this.receipt.datetime_justification.length < 10){
                alert("Please provide a justification to continue! Must be at least 10 characters long.");
                return;
            }

            this.step = 6;
            this.$emit('pendingLock', false);
        },  
        updateExamples(){
            this.searchTerm = `${this.vehicle_two.make || ''} ${this.vehicle_two.model || ''} ${this.vehicle_two.colour || ''} ${this.year || ''}`;
            this.loadExampleImages();
        },
        updateSearchTerm(){
            this.searchTerm = `${this.claim.make || ''} ${this.claim.model || ''} ${this.claim.colour || ''} ${this.year || ''}`;
            this.loadExampleImages();
        },
        loadExampleImages(){
            axios.get(`https://api.varsanpr.com/api/misc/imagesearch?search=${this.searchTerm}`, {
                headers: authHeader()
            })
            .then((response) => {
                this.exampleImages = response.data.images;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        generateDVLAEnquiry(){

            this.saving = true;

            let data = {
                client_id: this.client_id,
                fuels: [],
                fuelJustification: this.receipt.justification,
                vehicle_in_image: this.vehicle.vehicle_in_image,
                registration_visible: this.vehicle.registration_visible,
                vehicleJustification: this.vehicle.justification,
                mmc_match: this.vehicle_two.mmc_match,
                make: this.vehicle_two.make,
                model: this.vehicle_two.model,
                colour: this.vehicle_two.colour,
                vehicleTwoJustification: this.vehicle_two.justification,
                fuelTypeJustification: this.fuelType.justification,
                site_confirmed: this.fuelType.site_confirmed,
                site_response: this.fuelType.site_response,
                contacted_site: this.fuelType.contacted_site,
                no_image_available: this.vehicle.no_image_available ? 'Yes' : 'No',
                datetime_justification: this.receipt.datetime_justification,
                receipt_after_expected_contact: this.receipt.receipt_after_expected_contact,
                receipt_after_expected_explanation: this.receipt.receipt_after_expected_explanation,
                receipt_after_expected: this.receipt.receipt_after_expected,
                nozzle_back_matches: this.receipt.nozzle_back_matches,
                nozzle_back: this.receipt.nozzle_back,
            };

            data.fuels.push({
                type: this.receipt.fuel_type,
                litres: this.receipt.litres,
                value: this.receipt.value,
                pump: this.receipt.pump,
                is_cannister: this.receipt.is_cannister ? 1 : 0
            })

            if(this.receipt.fuel_second){
                data.fuels.push({
                    type: this.receipt.fuel_second,
                    litres: this.receipt.litres_second,
                    value: this.receipt.value_second,
                    pump: this.receipt.pump_second,
                    is_cannister: this.receipt.is_cannister_second ? 1 : 0
                });
            }

            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}`, data, {
                headers: authHeader()
            })
            .then((response) => {
                this.saving = false;
                console.log(response);
                this.downloading = true;
                this.saved = true;
                this.downloadEnquiry();
            })
            .catch((error) => {
                this.saving = false;
                console.log(error);
                alert('There was an issue updating the claim.');
            });

        },
        downloadEnquiry(){
            axios.get(`https://api.varsanpr.com/api/claims/${this.claim.id}/dvlaenquiry?client_id=${this.client_id}`, {responseType: 'arraybuffer', headers: authHeader()})
            .then(response => {
                saveAs(new Blob([response.data]), 'dvla.pdf');
                this.$success('Downloaded DVLA Enquiry Form');
                this.downloading = false;
                this.downloaded = true;
            })
            .catch(error => {
                console.log(error);
                this.$error('There was an issue downloading the DVLA Enquiry Form');
                this.downloading = false;
            });
        },
        async awaitingEvidence(type = 'vehicle_image'){

            if(type == 'vehicle_image'){

                let assets = this.claim.assets.filter((asset) => {
                    return asset.type == 'vehicle_image'
                });

                for await (let asset of assets){
                    try{
                        let response = await axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${asset.filename}`, {
                            type: 'additional_information',
                            client_id: this.client_id
                        }, {
                            headers: authHeader()
                        });

                        if(response.data.success == false){
                            console.log(response.data);
                            throw new Error('Failed to update asset type.');
                        }
                    }catch(err){
                        alert('There was an issue updating the asset type. The evidence tab has now been unlocked, please manually update the vehicle image to be additional information and set to awaiting evidence.');
                        return;
                    }

                }

            }

            if(type == 'till_receipt'){

                let assets = this.claim.assets.filter((asset) => {
                    return asset.type == 'till_receipt'
                });

                for await (let asset of assets){

                    try{

                        let response = await axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/evidence/${asset.filename}`, {
                            type: 'additional_information',
                            client_id: this.client_id
                        }, {
                            headers: authHeader()
                        });

                        if(response.data.success == false){
                            console.log(response.data);
                            throw new Error('Failed to update asset type.');
                        }
                    }catch(err){
                        alert('There was an issue updating the asset type. The evidence tab has now be unlocked, please manually update the till receipt to be additional information and set to awaiting evidence.');
                        return;
                    }

                }

            }

            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/changestatus`, {
                    client_id: this.client_id,
                    status: 'Awaiting Evidence'
                }, {
                    headers: authHeader()
                })
                .then(response => {
                    if(response.data.success){
                        this.$success("Moved claim to Awaiting Evidence");
                        this.$emit('setstatus', 'Awaiting Evidence');
                    }else{
                        this.$error(response.data.message);
                    }
                })
                .catch(error => {
                    this.$error("Failed to move claim to Awaiting Evidence", error);
                })

        },
        tamperedPlate(){

            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/progress`, {
                message: 'Tampered Plate',
                client_id: this.client_id
            }, {
                headers: authHeader()
            })
            .then(response => {
                if(response.status === 200){
                    this.$emit('setstatus', 'Pending');
                    this.$success("Successfully updated progress message!");
                }else{
                    throw new Error("Failed to update progress message.");
                }
            })
            .catch(error => {
                this.$error("Failed to update progress message.", error);
            });

        },
        cancelClaim(){

            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/changestatus`, {
                reason: 'The fuel dispensed does not match the vehicle and we were unable to justify why. Please contact us if you have any questions.',
                client_id: this.client_id,
                whitelist: false,
                paidonsite: false,
                status: 'Cancelled'
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$emit('changeMade');
                this.$emit('setstatus', 'Cancelled');
            })
            .catch(error => {
                this.$error("Failed to cancel claim.", error);
            });

        },
        atDvla(){
            axios.patch(`https://api.varsanpr.com/api/claims/${this.claim.id}/changestatus`, {
                client_id: this.client_id,
                status: 'At DVLA',
                reason: 'DVLA Enquiry Form posted'
            }, {
                headers: authHeader()
            })
            .then(response => {
                this.$emit('changeMade');
                this.$emit('setstatus', 'At DVLA');
                this.$emit('reload');
                this.$success("Successfully updated claim status!");
            })
            .catch(error => {
                this.$error("Failed to update claim status.", error);
            });
        }
    },
    computed: {
        till_receipt: function(){
            return this.claim.assets.filter((asset) => {
                return asset.type == 'till_receipt'
            })[0] || null;
        },
        vehicle_image: function(){
            return this.claim.assets.filter((asset) => {
                return asset.type == 'vehicle_image'
            })[0] || null;
        },
        timestamp: function(){
            return DateTime.fromSeconds(this.claim.incident_timestamp).toFormat('dd/MM/yyyy HH:mm:ss');
        },
        in_future: function(){
            return DateTime.fromSeconds(this.claim.incident_timestamp) > DateTime.local();
        },
        till_file_type: function(){
            if(this.till_receipt){
                return this.till_receipt.filename.slice((this.till_receipt.filename.lastIndexOf(".") - 1 >>> 0) + 2);
            }
        },
        vehicle_file_type: function(){
            if(this.vehicle_image){
                return this.vehicle_image.filename.slice((this.vehicle_image.filename.lastIndexOf(".") - 1 >>> 0) + 2);
            }
        },
        pendingLock(){
            if(this.step == 3){
                console.log('Pending Lock Override: Yes');
                this.$emit('pendingLock', true);
                return false;
            }

            if(this.step == 1 && this.receipt.can_read == 'No'){
                console.log('Pending Lock Override: Yes');
                this.$emit('pendingLock', true);
                return false;
            }

            console.log('Pending Lock Override: No');
            this.$emit('pendingLock', false);
            return true;
        },
        claimFuelType(){
            return this.claim.override_mmc == 1 ? this.claim.vehicle_fueltype : this.claim.fuelType;
        }
    },
    watch: {
        'vehicle.registration': function(newVal, oldVal){
            if(newVal === null || newVal === undefined) return;
            if(newVal.length > 11){
                this.vehicle.registration = oldVal;
            }
            this.vehicle.registration = newVal.toUpperCase();
        }
    }
}
</script>

<style scoped>

.carousel-control-prev-icon::before, .carousel-control-next-icon::before {
    background-color: #000000;
    border-radius: 7px;
    padding: 10px;
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotate-270 {
    transform: rotate(270deg);
}

</style>
